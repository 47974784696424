import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import reauthenticateUser from '../../util/reauthenticateUser';
import unenrollCurrentPhone from '../../util/unenrollCurrentPhone';
import { colors, fonts } from '../../assets/css/theme';

const EditPhoneNumber = ({ setPhoneNumber }) => {
  const [newPhone, setNewPhone] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isReauthenticated, setIsReauthenticated] = useState(false);
  const [isReauthenticating, setIsReauthenticating] = useState(false);
  const [phoneUpdated, setPhoneUpdated] = useState(false);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => console.log('reCAPTCHA solved:', response),
        }
      );
    }

    return () => {
      // Cleanup function to avoid multiple instances
      //console.log('Cleaning up reCAPTCHA instance...');
      if (window.recaptchaVerifier) {
        try {
          window.recaptchaVerifier.clear();
          window.recaptchaVerifier = null;
        } catch (error) {
          console.warn('Error clearing reCAPTCHA:', error);
        }
      }
    };
  }, []);

  const handleReauthentication = async () => {
    //console.log('Update Phone button clicked...');
    setIsReauthenticating(true);

    try {
      await reauthenticateUser(setIsReauthenticated, setIsReauthenticating);
    } catch (error) {
      console.error('Reauthentication failed:', error);
    } finally {
      setIsReauthenticating(false);
    }
  };

  const normalizePhoneNumber = (phone) => {
    if (!phone) return '';
    let cleanedPhone = phone.replace(/[^+\d]/g, '');

    if (cleanedPhone.startsWith('0')) {
      cleanedPhone = `+44${cleanedPhone.slice(1)}`;
    }
    if (!cleanedPhone.startsWith('+')) {
      cleanedPhone = `+1${cleanedPhone}`;
    }
    return cleanedPhone;
  };

  const sendVerificationCode = async () => {
    if (!newPhone.trim()) {
      setError('Please enter a valid phone number.');
      return;
    }

    if (!isReauthenticated) {
      setError('Please reauthenticate before changing the phone number.');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      setSuccess(null);

      const cleanedPhone = normalizePhoneNumber(newPhone);
      // console.log('Sending verification code to:', cleanedPhone);

      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error('No authenticated user found.');
      }

      // Step 1: Unenroll current MFA phone before updating
      if (user.multiFactor.enrolledFactors.length > 0) {
        // console.log('Unenrolling current MFA phone before updating...');
        await user.multiFactor.unenroll(
          user.multiFactor.enrolledFactors[0].uid
        );
        // console.log('Old phone number unenrolled from MFA.');
      } else {
        // console.log('No MFA phone number to unenroll.');
      }

      if (!window.recaptchaVerifier) {
        //console.warn('RecaptchaVerifier is missing, reinitializing...');
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: (response) => console.log('reCAPTCHA solved:', response),
          }
        );
      }

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      const id = await phoneAuthProvider.verifyPhoneNumber(
        cleanedPhone,
        window.recaptchaVerifier
      );

      //console.log('Verification ID received:', id);
      setVerificationId(id);
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError(err.message || 'Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const verifyCodeAndUpdatePhone = async () => {
    if (!code.trim()) {
      setError('Please enter the verification code.');
      return;
    }

    try {
      setLoading(true);
      setError('');
      setSuccess(null);

      const user = firebase.auth().currentUser;
      if (!user) {
        setError('No authenticated user found.');
        return;
      }

      //console.log('Checking verificationId:', verificationId);
      if (!verificationId) {
        setError(
          'Verification ID is missing. Request a new OTP code to your phone.'
        );
        setLoading(false);
        return;
      }

      // Unenroll current phone before updating
      await unenrollCurrentPhone();

      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        code
      );

      const assertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(credential);

      await user.multiFactor.enroll(assertion, 'Phone 2FA');

      //console.log('Phone number updated successfully.');
      setPhoneNumber(newPhone);
      setSuccess('Phone number updated successfully!');
      setPhoneUpdated(true);
    } catch (err) {
      console.error('Phone number update failed:', err);
      if (err.code === 'auth/invalid-verification-code') {
        setError('Invalid verification code. Please try again.');
      } else if (err.code === 'auth/session-expired') {
        setError('Session expired. Please request a new OTP.');
      } else {
        setError(err.message || 'Failed to update phone number.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div id="recaptcha-container"></div>
      {!isReauthenticated ? (
        <div className="input-group">
          <button
            className="primary-button"
            onClick={handleReauthentication}
            disabled={isReauthenticating}
          >
            {isReauthenticating ? 'Authenticating...' : 'Update Phone'}
          </button>
        </div>
      ) : !phoneUpdated ? (
        <>
          <div className="input-group">
            <input
              type="tel"
              value={newPhone}
              onChange={(e) => setNewPhone(e.target.value)}
              placeholder="Enter new phone number"
              className="styled-input"
            />
            <button
              className="primary-button"
              onClick={sendVerificationCode}
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send Code'}
            </button>
          </div>
          {verificationId && (
            <div className="input-group">
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Enter verification code"
                className="styled-input"
              />
              <button
                className="primary-button"
                onClick={verifyCodeAndUpdatePhone}
                disabled={loading}
              >
                Verify & Update
              </button>
            </div>
          )}
        </>
      ) : null}
      {error && <p className="error-text">{error}</p>}
      {success && <p className="success-text">{success}</p>}{' '}
      <style jsx>{`
        .input-group {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 8px;
        }

        .styled-input {
          flex: 1;
          ${fonts.reg};
          padding: 8px 12px;
          border-radius: 6px;
          border: 1px solid #ccc;
          background: #ffffff;
          font-size: 14px;
          transition: border 0.3s ease-in-out;
        }

        .styled-input:focus {
          border-color: ${colors.primary};
          outline: none;
        }

        .primary-button {
          ${fonts.reg};
          background: ${colors.loBlue};
          color: white;
          padding: 8px 16px;
          font-size: 14px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: background 0.3s ease-in-out;
        }

        .primary-button:hover {
          background: ${colors.darkBlue};
        }

        .error-text {
          color: ${colors.error};
          font-size: 13px;
          margin-top: 5px;
        }

        .success-text {
          color: green;
          font-size: 13px;
          margin-top: 5px;
        }
      `}</style>
    </div>
  );
};

export default EditPhoneNumber;

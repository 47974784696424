import firebase from 'firebase/app';
import 'firebase/auth';

const unenrollCurrentPhone = async () => {
  const user = firebase.auth().currentUser;
  if (!user) {
    console.error('No authenticated user found.');
    return;
  }

  const enrolledFactors = user.multiFactor.enrolledFactors;
  if (enrolledFactors.length === 0) {
    console.warn('No MFA phone number found.');
    return;
  }

  try {
    await user.multiFactor.unenroll(enrolledFactors[0].uid);
    console.log('✅ Phone number unenrolled from MFA.');
  } catch (error) {
    console.error('Failed to unenroll MFA phone:', error);
  }
};

export default unenrollCurrentPhone;

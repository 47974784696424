import firebase from 'firebase/app';

export const placeOrder = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        await firebase
          .app()
          .functions('europe-west2')
          .httpsCallable('confirmUserCart')({});
        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  };
};

export const handleCustomerChangeOnCart = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let user = getState().auth.authedUser;
        await firebase
          .firestore()
          .collection('userCarts')
          .doc(user.uid)
          .set({});
      } catch (e) {
        console.error(e);
      }
    });
  };
};

export const updateOrderKey = (key, value) => {
  return (dispatch, getState) => {
    let user = getState().auth.authedUser;
    let customer = getState().customer.profile;
    let cart = getState().cart.cart;

    let attrs = {
      ShipTo: cart.ShipTo || customer.DefaultShipTo || null,
      BillTo: cart.BillTo || customer.DefaultBillTo || null,
    };
    attrs[key] = value;
    firebase
      .firestore()
      .collection('userCarts')
      .doc(user.uid)
      .set(attrs, { merge: true });
  };
};

export const updateLineItems = (itemCode, qty) => {
  return (dispatch, getState) => {
    //is the an existing item
    let user = getState().auth.authedUser;
    let customer = getState().customer.profile;
    let cart = getState().cart.cart;

    let itemsByKey = getState().cart.itemsByKey;
    let itemsArray = getState().cart.cart.Lines || [];
    let customerPriceList = getState().customer.priceList;
    if (customerPriceList[itemCode]) {
      let unitPrice = customerPriceList[itemCode];

      if (itemsByKey[itemCode]) {
        //there is an existing item to modify
        let previousLine = itemsByKey[itemCode];
        previousLine.Quantity = qty;
        previousLine.UnitPrice = unitPrice.Price;
        previousLine.LineTotal =
          (Math.round(unitPrice.Price * 100) * qty) / 100;
        itemsArray[previousLine.LineNumber] = previousLine;
      } else {
        //just create a new line item
        itemsArray.push({
          ItemCode: itemCode,
          Quantity: qty,
          LineNumber: itemsArray.length,
          UnitPrice: unitPrice.Price,
          LineTotal: unitPrice.Price * qty,
        });
      }
      //filter any zero lines and update the line numbers

      let totalCostPence = 0;

      itemsArray = itemsArray
        .filter((i) => {
          return i.Quantity > 0;
        })
        .map((i, idx) => {
          i.LineNumber = idx;
          totalCostPence += Math.round(i.LineTotal * 100); //add the costs to the total;
          return i;
        });

      let taxTotalPence = Math.round(totalCostPence * 0.2);
      let totalPence = totalCostPence + taxTotalPence;

      //always set ill

      firebase
        .firestore()
        .collection('userCarts')
        .doc(user.uid)
        .set(
          {
            Lines: itemsArray,
            TaxTotal: taxTotalPence / 100,
            Total: totalPence / 100,
            SAPBP: customer.SAPBP,
            ShipTo: cart.ShipTo || customer.DefaultShipTo || null,
            BillTo: cart.BillTo || customer.DefaultBillTo || null,
            orderDate: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
    } else {
      dispatch({
        type: 'UPDATE_CART_LINE_ITEM_ERROR',
        payload: {
          itemCode: itemCode,
          qty: qty,
          message: 'user does not have a price for this item',
        },
      });
    }
  };
};

import React from 'react';
import { connect } from 'react-redux';
import AddressSelector from '../common/AddressSelector';
import ColTitle from './ColTitle';
import { fonts, colors } from '../../assets/css/theme';
import OrderStepper from './OrderStepper';
import { updateOrderKey, placeOrder } from '../../actions/cartAction';
import Label from './Label';
import CustomerRef from './CustomerRef';
import Button from './Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import Status from './Status';
import OrderMetaStatus from './OrderMetaStatus';
import PopupTitle from './PopupTitle';

const MySwal = withReactContent(Swal);

const OrderDetail = (props) => {
  if (!props.detail) return null;

  let content = props.detail;
  if (props.type === 'date') {
    content = moment(props.detail).format('DD-MM-YYYY');
  }
  if (props.type === 'status') {
    content = <Status mode="order">{props.detail}</Status>;
  }

  return (
    <div className="wrap" style={{ ...props.style }}>
      <div className="label">{props.label}</div>
      <div className="value">{content}</div>
      <style jsx>{`
        .wrap {
          display: flex;
          align-items: center;
          margin-top: 5px;
          margin-bottom: 5px;
          ${fonts.bold}
        }
        .label {
          margin: 0;
          margin-right: 10px;
        }
        .value {
          color: ${colors.midBlue};
        }
      `}</style>
    </div>
  );
};

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.renderOrderHeader = this.renderOrderHeader.bind(this);
    this.renderOrderRows = this.renderOrderRows.bind(this);
    this.renderPlaceButton = this.renderPlaceButton.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
    this.state = {
      working: false,
      successModal: false,
    };
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  calculateSubtotal(lines) {
    if (!lines) return 0;
    return lines.reduce((sum, item) => sum + item.LineTotal, 0);
  }

  renderOrderHeader() {
    return (
      <div className="Orders">
        <div className="item">
          <div className="code">
            <ColTitle>
              Code<span className="print-only">/GTIN</span>
            </ColTitle>
          </div>
          <div className="gtin no-print">
            <ColTitle>GTIN</ColTitle>
          </div>
          <div className="title">
            <ColTitle>Product Name</ColTitle>
          </div>
          {this.props.title !== 'Delivery' ? (
            <div className="price">
              <ColTitle>Unit Price</ColTitle>
            </div>
          ) : (
            <div></div>
          )}
          <div className="orderTag">
            <ColTitle>Order Quantity</ColTitle>
          </div>
          {this.props.title !== 'Delivery' ? (
            <div className="lineTotal">
              <ColTitle>Line Total</ColTitle>
            </div>
          ) : (
            <div></div>
          )}
          <style jsx>{`
            .item {
              display: flex;
              ${fonts.reg}
              font-size:14px;
              padding: 10px 0px;
              align-items: center;
              border-bottom: 1px solid ${colors.mid};
              background: #fff;
            }
            .code {
              width: 100px;
            }

            .gtin {
              width: 130px;
              text-align: center;
            }
            .title {
              flex: 1;
            }
            .type {
              ${fonts.bold}
              font-size:12px;
              color: ${colors.mid};
            }
            .name {
              ${fonts.bold}
              font-size:16px;
            }
            .orderTag {
              width: 120px;
              text-align: center;
            }
            .price {
              width: 90px;
              ${fonts.bold}
              font-size:16px;
              text-align: center;
            }
            .lineTotal {
              width: 90px;
              ${fonts.bold}
              font-size:16px;
              text-align: right;
            }
            @media screen and (max-width: 767px) {
              .item {
                display: none;
              }
            }
            @media print {
              .code {
                padding-right: 10px;
                width: 120px;
                text-overflow: ellipsis;
              }
            }
          `}</style>
        </div>
      </div>
    );
  }

  renderOrderRows() {
    if (!this.props.order || !this.props.order.Lines) return null;

    const getPrice = (i) => {
      return i.UnitPrice && this.props.title !== 'Delivery'
        ? '£' + i.UnitPrice.toFixed(2)
        : '';
    };
    const getOrderPicker = (i) => {
      if (this.props.editable) {
        return <OrderStepper itemCode={i.ItemCode} />;
      }
      return i.Quantity;
    };

    const getLineTotal = (i) => {
      return i.LineTotal && this.props.title !== 'Delivery'
        ? `£${i.LineTotal.toFixed(2)}`
        : '';
    };

    return this.props.order.Lines.map((i, idx) => {
      let item = this.props.itemsByKey[i.ItemCode] || {};
      return (
        <div class="Orders">
          <div className="item" key={i.ItemCode + '-' + idx}>
            <div className="code">
              {i.ItemCode}
              <span className="print-only">{item.GTIN}</span>
            </div>
            <div className="gtin no-print">{item.GTIN}</div>
            <div className="title">
              <div className="type">
                {item.Group === 'GOL' ? 'Overlabelled' : 'Medical Product'}
              </div>
              <div className="name">{item.ItemName}</div>
              <OrderMetaStatus
                line={i}
                order={this.props.order}
              ></OrderMetaStatus>
            </div>

            {this.props.title !== 'Delivery' ? (
              <div className="price">{getPrice(i)}</div>
            ) : (
              <div></div>
            )}
            <div className="orderTag">{getOrderPicker(i)}</div>
            {this.props.title !== 'Delivery' ? (
              <div className="lineTotal">{getLineTotal(i)}</div>
            ) : (
              <div></div>
            )}
            <style jsx>{`
              .item {
                display: flex;
                ${fonts.reg}
                font-size:14px;
                padding: 10px 0px;
                align-items: center;
                border-bottom: 1px solid ${colors.mid};
                background: #fff;
              }
              .code {
                width: 100px;
              }

              .gtin {
                width: 130px;
                text-align: center;
              }
              .title {
                flex: 1;
              }
              .type {
                ${fonts.bold}
                font-size:12px;
                color: ${colors.mid};
              }
              .name {
                ${fonts.bold}
                font-size:14px;
              }
              .price {
                width: 90px;
                ${fonts.bold}
                font-size:14px;
                text-align: center;
              }
              .orderTag {
                width: 120px;
                ${fonts.bold}
                font-size:14px;
                text-align: center;
              }
              .lineTotal {
                width: 90px;
                ${fonts.bold}
                font-size:14px;
                text-align: right;
              }
              @media screen and (max-width: 767px) {
                .item {
                  flex-wrap: wrap;
                }
                .code,
                .gtin,
                .name,
                .price {
                  width: 100%;
                  text-align: left;
                }
                .price {
                  margin-bottom: 10px;
                }
                .title {
                  width: 100%;
                  padding-left: 0px;
                  display: block;
                }
                .lineTotal {
                  flex: 1;
                  font-size: 22px;
                }
                .orderTag {
                  text-align: left;
                }
                .orderTag:before {
                  content: 'Qty: ';
                }
              }

              @media print {
                .item,
                .price,
                .orderTag,
                .lineTotal {
                  font-size: 12px;
                }
                .code {
                  padding-right: 10px;
                  width: 120px;
                  text-overflow: ellipsis;
                }
              }
            `}</style>
          </div>
        </div>
      );
    });
  }

  renderPlaceButton() {
    if (this.props.editable) {
      return (
        <div className="control">
          <Button
            working={this.state.working}
            onClick={this.placeOrder}
            style={{ width: 120 }}
          >
            Place Order
          </Button>
          <style jsx>{`
            .control {
              margin-top: 20px;
              text-align: right;
            }
          `}</style>
        </div>
      );
    }
    return null;
  }

  async placeOrder() {
    let orderRef = this.props.order.CustomerRef;
    if (this.state.working) return false;

    if (!orderRef) {
      /*let response =*/ await MySwal.fire({
        title: 'There was a problem',
        text: 'Please add a purchase order number before placing your order',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
      return;
    }

    try {
      this.setState({ working: true });
      await this.props.placeOrder();

      if (this.props.onOrderPlaced) {
        this.props.onOrderPlaced();
      } else {
        this.setState({ working: false });
      }

      let response = await MySwal.fire({
        title: 'Thankyou',
        text: `Your order ${orderRef} has been created and sent through for processing.`,
        type: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
      if (response.value) {
      }
    } catch (e) {
      /*let response =*/ await MySwal.fire({
        title: 'There was a problem',
        text: e,
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
      this.setState({ working: false });
    }
  }

  render() {
    if (!this.props.order || !this.props.order.Total) return null;

    const subtotal = this.calculateSubtotal(this.props.order.Lines);
    const taxTotal = this.props.order.TaxTotal || 0;
    const total = subtotal + taxTotal;

    let getTitle = () => {
      switch (this.props.title) {
        case 'Delivery':
          return 'Despatch Date:';
        case 'Invoice':
          return 'Invoice Date:';
        case 'Order':
          return 'Order date:';
        default:
          return 'Order date:';
      }
    };

    return (
      <div className="Order">
        <PopupTitle>{this.props.title}</PopupTitle>

        <div className="top-section">
          <div>
            {this.props.title === 'Invoice' ? (
              <OrderDetail
                detail={this.props.order.id}
                label="Invoice Number: "
              />
            ) : (
              <div></div>
            )}
            <OrderDetail
              type="date"
              detail={this.props.order.DocDate}
              label={getTitle()}
            />
            <OrderDetail
              detail={this.props.order.OrderPlacedByName}
              label="Ordered by: "
            />
          </div>
          <div>
            <OrderDetail
              type="status"
              detail={this.props.order.Status}
              label="Order status: "
            />
          </div>
        </div>

        <CustomerRef
          editable={this.props.editable}
          value={this.props.order.CustomerRef}
          onChange={(val) => {
            this.props.updateOrderKey('CustomerRef', val);
          }}
        />
        <div className="addresses">
          <div className="address">
            <Label style={{ marginBottom: 5 }}>Ship to</Label>
            <AddressSelector
              editable={this.props.editable}
              defaultKey="DefaultShipTo"
              value={this.props.cartShipToOrCustomerDefaultShipTo}
              onChange={(val) => {
                this.props.updateOrderKey('ShipTo', val.value);
              }}
            />
          </div>
          <div className="address">
            <Label style={{ marginBottom: 5 }}>Invoice to</Label>
            <AddressSelector
              editable={this.props.editable}
              defaultKey="DefaultBillTo"
              value={this.props.cartBillToOrCustomerDefaultBillTo}
              onChange={(val) => {
                this.props.updateOrderKey('BillTo', val.value);
              }}
            />
          </div>
        </div>

        {this.renderOrderHeader()}
        {this.renderOrderRows()}

        <div className="footer">
          <div className="webOrder"></div>
          <div className="total-section">
            <div className="total">
              {this.props.title !== 'Delivery' ? (
                <div className="label">Subtotal: </div>
              ) : (
                <div></div>
              )}
              <div className="value">
                {this.props.order.Total &&
                this.props.order.TaxTotal &&
                this.props.title !== 'Delivery'
                  ? `£${subtotal.toFixed(2)}`
                  : ''}
              </div>
            </div>
            <div className="total">
              {this.props.title !== 'Delivery' ? (
                <div className="label">VAT: </div>
              ) : (
                <div></div>
              )}
              <div className="value">
                {this.props.order.TaxTotal && this.props.title !== 'Delivery'
                  ? `£${taxTotal.toFixed(2)}`
                  : ''}
              </div>
            </div>

            <div className="total">
              {this.props.title !== 'Delivery' ? (
                <div className="label">Total: </div>
              ) : (
                <div></div>
              )}
              <div className="value">
                {this.props.order.Total &&
                this.props.order.TaxTotal &&
                this.props.title !== 'Delivery'
                  ? `£${total.toFixed(2)}`
                  : ''}
              </div>
            </div>

            {this.renderPlaceButton()}
          </div>
        </div>

        <style jsx>{`
          .addresses {
            display: flex;
            margin-bottom: 40px;
          }
          .address {
            margin-right: 30px;
            width: 260px;

            margin-bottom: 10px;
          }
          .footer {
            display: flex;
            justify-content: space-between;
          }
          .total {
            ${fonts.bold}
            display: flex;
            margin-top: 20px;
            line-height: 1em;
            width: 150px;
          }
          .label {
            width: 170px;
            margin-right: 0px;
            text-align: right;
          }
          .label.large {
            line-height: 26px;
          }
          .value {
            width: 170px;
            margin-left: 0px;
            text-align: right;
          }
          .value.large {
            font-size: 26px;
          }

          .top-section {
            display: flex;
            justify-content: space-between;
          }

          @media screen and (max-width: 767px) {
            .addresses {
              display: block;
            }
            .footer {
              display: block;
            }
            .top-section {
              display: block;
            }
          }
          @media print {
            .value.large {
              font-size: 28px;
            }
            .top-section {
              display: block;
            }
          }
        `}</style>
      </div>
    );
  }
}

Order.propTypes = {};

const mapStateProps = (state) => {
  return {
    itemsByKey: state.customer.itemsByKey,
    cartShipToOrCustomerDefaultShipTo:
      state.cart.cart.ShipTo || state.customer.profile.DefaultShipTo,
    cartBillToOrCustomerDefaultBillTo:
      state.cart.cart.BillTo || state.customer.profile.DefaultBillTo,
  };
};

export default connect(mapStateProps, { updateOrderKey, placeOrder })(Order);

import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import withRouter from '../../util/withRouter';
import { colors, fonts, effects } from '../../assets/css/theme';
import { logoutUser } from '../../actions/authAction';
import { switchCustomer } from '../../actions/appAction';
import Select from 'react-select';
class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.setInternals = this.setInternals.bind(this);
    this.state = {
      value: null,
      options: [],
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.setInternals(this.props);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setInternals(newProps);
  }

  setInternals(props) {
    let options = [];
    let selected = null;
    if (props.groupCustomers) {
      props.groupCustomers.map((c) => {
        // FIXME: Probably needs to be a forEach instead of a map
        options.push({
          value: c.SAPBP,
          label: c.SAPBP + ' : ' + c.Name,
          customer: c,
        });
        if (c.SAPBP === props.customer.SAPBP) {
          selected = {
            value: c.SAPBP,
            label: c.SAPBP + ' : ' + c.Name,
            customer: c,
          };
        }
      });
    }
    this.setState({
      options: options,
      value: selected,
    });
  }

  render() {
    const renderCustomerSwitch = () => {
      if (!this.props.groupCustomers || this.props.groupCustomers.length < 2)
        return null;

      return (
        <div className="small-only">
          <div className="menu-link">
            <span>Switch Location</span>
            <div className="selector">
              <Select
                options={this.state.options}
                onChange={(e) => {
                  this.setState(
                    {
                      value: e,
                    },
                    () => {
                      this.props.switchCustomer(e.customer);
                      this.props.onRouteChange();
                    }
                  );
                }}
                value={this.state.value}
              />
            </div>
          </div>
          <style jsx="true">{`
            .small-only {
              display: none;
            }
            .selector {
              margin-top: 3px;
              font-size: 15px;
              color: ${colors.loBlue};
            }
            @media all and (max-width: 767px) {
              .small-only {
                display: block;
              }
            }
          `}</style>
        </div>
      );
    };

    return (
      <div className="Menu">
        {renderCustomerSwitch()}

        <NavLink
          onClick={this.props.onRouteChange}
          className="menu-link"
          exact="true"
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          onClick={this.props.onRouteChange}
          className="menu-link"
          to="/catalogue"
        >
          Catalogue
        </NavLink>
        <NavLink
          onClick={this.props.onRouteChange}
          className="menu-link"
          to="/orders"
        >
          Orders
        </NavLink>
        <NavLink
          onClick={this.props.onRouteChange}
          className="menu-link"
          to="/deliveries"
        >
          Deliveries
        </NavLink>
        <NavLink
          onClick={this.props.onRouteChange}
          className="menu-link"
          to="/invoices"
        >
          Invoices
        </NavLink>
        <NavLink
          onClick={this.props.onRouteChange}
          className="menu-link"
          to="/credits"
        >
          Credits
        </NavLink>

        <NavLink
          onClick={this.props.onRouteChange}
          className="menu-link"
          to="/profile"
        >
          Profile
        </NavLink>

        <div className="small-only">
          <div
            className="menu-link"
            style={{ marginTop: 60 }}
            onClick={() => {
              this.props.logoutUser();
            }}
          >
            {' '}
            Log Out{' '}
          </div>
        </div>

        <style global="true" jsx="true">{`
          .Menu .menu-link {
            display: block;
            padding: 10px;
            background: #fff;
            ${fonts.bold}
            color:${colors.midBlue};
            font-size: 16px;
            border-radius: 0px 20px 20px 0px;
            margin-bottom: 15px;
            ${effects.shadow1}
            text-decoration: none;
            transition: all 0.3s;
          }
          .Menu .menu-link.active,
          .Menu .menu-link.active:hover {
            background: ${colors.loBlue};
            color: #fff;
          }

          .Menu .menu-link:hover {
            background: ${colors.midBlue};
            color: #fff;
          }
          .small-only {
            display: none;
          }

          @media all and (max-width: 767px) {
            .small-only {
              display: block;
            }
          }
        `}</style>
      </div>
    );
  }
}

Menu.propTypes = {};

const mapStateProps = (state) => {
  return {
    groupCustomers: state.customer.groupCustomers,
    customer: state.customer.profile,
  };
};

export default withRouter(
  connect(mapStateProps, { logoutUser, switchCustomer })(Menu)
);

import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import reauthenticateUser from '../../util/reauthenticateUser';
import { colors, fonts } from '../../assets/css/theme';

const Enable2FA = ({ setMfaEnabled, setPhoneNumber }) => {
  const [phone, setPhone] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [isReauthenticated, setIsReauthenticated] = useState(false);
  const [isReauthenticating, setIsReauthenticating] = useState(false);

  useEffect(() => {
    // Ensure reCAPTCHA is properly initialized
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => console.log('reCAPTCHA solved:', response),
        }
      );
    }
    setRecaptchaVerifier(window.recaptchaVerifier);

    return () => {
      // Cleanup to avoid multiple instances
      // if (window.recaptchaVerifier) {
      //   window.recaptchaVerifier.clear();
      // }
    };
  }, []);

  const handleReauthentication = async () => {
    try {
      await reauthenticateUser(setIsReauthenticated, setIsReauthenticating);
    } catch (error) {
      console.error(error);
    }
  };

  const normalizePhoneNumber = (phone) => {
    if (!phone) return '';

    // Remove spaces, dashes, and other special characters (except + at start)
    let cleanedPhone = phone.replace(/[^+\d]/g, '');

    // If the number starts with 0 (UK example), assume missing country code
    if (cleanedPhone.startsWith('0')) {
      console.warn('Local number detected, assuming UK (+44)...');
      cleanedPhone = `+44${cleanedPhone.slice(1)}`;
    }

    // Ensure the number starts with a single + (avoid double country codes)
    if (!cleanedPhone.startsWith('+')) {
      console.warn('No country code detected, assuming +1 (US/Default)...');
      cleanedPhone = `+1${cleanedPhone}`;
    }

    return cleanedPhone;
  };

  const sendVerificationCode = async () => {
    if (!phone.trim()) {
      setError('Phone number is required.');
      return;
    }

    const cleanedPhone = normalizePhoneNumber(phone);
    //console.log('Normalized phone number:', cleanedPhone);

    if (!isReauthenticated) {
      setError('Please reauthenticate before sending the code');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Check if the phone number is a Firebase test number
      const testPhoneNumbers = ['+16505553434', '+447700900000'];
      if (testPhoneNumbers.includes(cleanedPhone)) {
        console.warn(
          'Using a test phone number. Skipping multi-factor session.'
        );

        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        //No session required for test numbers
        const id = await phoneAuthProvider.verifyPhoneNumber(
          cleanedPhone,
          window.recaptchaVerifier
        );

        //console.log('Verification ID received (test mode):', id);
        setVerificationId(id);
        return;
      }

      // console.log('Sending verification code to:', cleanedPhone);

      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => console.log('reCAPTCHA solved:', response),
        }
      );
      setRecaptchaVerifier(window.recaptchaVerifier);

      const user = firebase.auth().currentUser;
      const session = await user.multiFactor.getSession();

      //console.log('Multi-factor session obtained:', session);

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const id = await phoneAuthProvider.verifyPhoneNumber(
        { phoneNumber: cleanedPhone, session },
        window.recaptchaVerifier
      );

      // console.log('Verification ID received:', id);

      setVerificationId(id);
      setError(null);
    } catch (err) {
      const errorMessages = {
        'auth/wrong-password': 'Incorrect password. Please try again.',
        'auth/user-not-found': 'User not found. Please check your email.',
        'auth/network-request-failed': 'Network error. Check your connection.',
        'auth/too-many-requests': 'Too many failed attempts. Try again later.',
      };
      setError(
        errorMessages[err.code] || err.message || 'Something went wrong.'
      );
    }
  };

  const verifyCode = async () => {
    if (!code.trim()) {
      setError('Please enter the verification code.');
      return;
    }

    try {
      setLoading(true);
      setError('');

      // console.log('Checking verificationId:', verificationId);
      if (!verificationId) {
        throw new Error('Verification ID is missing. Request a new OTP.');
      }

      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        code
      );
      // console.log('OTP Credential:', credential);

      // Generate multiFactorAssertion
      const assertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
      // console.log('MultiFactorAssertion:', assertion);

      const user = firebase.auth().currentUser;
      await user.multiFactor.enroll(assertion, 'Phone 2FA');

      // console.log('Multi-factor authentication enabled successfully.');
      setMfaEnabled(true);
      setPhoneNumber(phone);
      setError(null);
    } catch (err) {
      // console.error('OTP Verification Error:', err);
      setError(err.message || 'Failed to verify OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div id="recaptcha-container"></div>
      {!isReauthenticated && (
        <div className="input-group">
          <button
            className="primary-button"
            onClick={handleReauthentication}
            disabled={isReauthenticating}
          >
            {isReauthenticating ? 'Authenticating...' : 'Reauthenticate'}
          </button>
        </div>
      )}
      {isReauthenticated && (
        <div className="input-group">
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter phone number"
            className="styled-input"
          />
          <button
            className="primary-button"
            onClick={sendVerificationCode}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Code'}
          </button>
        </div>
      )}
      {error && <p className="error-text">{error}</p>}
      {verificationId && (
        <>
          <div className="input-group">
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter verification code"
              className="styled-input"
            />
            <button className="primary-button" onClick={verifyCode}>
              Verify
            </button>
          </div>
          {error && <p className="error-text">{error}</p>}
        </>
      )}
      <style jsx>{`
        .input-group {
          display: flex;
          align-items: center;
          gap: 10px; /* Adds spacing between input and button */
          margin-top: 8px;
        }

        .styled-input {
          flex: 1;
          ${fonts.reg};
          padding: 8px 12px;
          border-radius: 6px;
          border: 1px solid #ccc;
          background: #ffffff;
          font-size: 14px;
          transition: border 0.3s ease-in-out;
        }

        .styled-input:focus {
          border-color: ${colors.primary};
          outline: none;
        }

        .primary-button {
          ${fonts.reg};
          background: ${colors.loBlue};
          color: white;
          padding: 8px 16px; /* Balanced padding */
          font-size: 14px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: background 0.3s ease-in-out;
        }

        .primary-button:hover {
          background: ${colors.darkBlue}; /* Slightly darker on hover */
        }

        .error-text {
          color: ${colors.error};
          font-size: 13px;
          margin-top: 5px;
        }
      `}</style>
    </div>
  );
};

export default Enable2FA;

import React from 'react';
import { connect } from 'react-redux';
import { colors, fonts, effects } from '../../assets/css/theme';
import { switchCustomer } from '../../actions/appAction';

class CustomerSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.renderSingleCustomer = this.renderSingleCustomer.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  renderSingleCustomer() {
    if (!this.props.customer) return null;
    return (
      <div className="userHospital">
        {this.props.customer.Name}
        <style jsx="true">{`
          .userHospital {
            color: ${colors.midBlue};
            ${fonts.bold};
            text-align: right;
            margin-top: 5px;
          }
        `}</style>
      </div>
    );
  }

  renderOptions() {
    return this.props.groupCustomers
      .filter((g) => {
        return g.SAPBP !== this.props.customer.SAPBP;
      })
      .map((g) => {
        return (
          <div
            className="select"
            key={g.SAPBP}
            onClick={() => {
              this.props.switchCustomer(g);
            }}
          >
            {g.Name}

            <style jsx="true">{`
              .select {
                padding: 5px;
                font-weight: bold;
                color: ${colors.loBlue};
                cursor: pointer;
              }
              .select:hover {
                background: ${colors.hiBlue};
              }
            `}</style>
          </div>
        );
      });
  }

  render() {
    if (
      this.props.groupCustomers &&
      Array.isArray(this.props.groupCustomers) &&
      this.props.groupCustomers.length < 2
    ) {
      return this.renderSingleCustomer();
    }
    return (
      <div className="CustomerSwitcher">
        <div className="label">Select Location</div>
        <div className="CustomerSwitcher-current">
          {this.props.customer.Name}
          <svg
            className="downArrow-svg"
            width="8"
            height="6"
            viewBox="0 0 8.643 6.461"
          >
            <path
              className="downArrow"
              d="M1723.785,38l4.555,6.461L1732.428,38Z"
              transform="translate(-1723.785 -38)"
            />
          </svg>
        </div>
        <div className="CustomerSwitcher-dropdown">{this.renderOptions()}</div>

        <style jsx="true">{`
          .CustomerSwitcher {
            position: relative;
          }

          .CustomerSwitcher-current {
            color: ${colors.midBlue};
            ${fonts.bold};
            text-align: right;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            border: 1px solid ${colors.mid};
            margin-right: -3px;
            cursor: pointer;
            font-size: 16px;
          }
          .label {
            text-align: right;
            font-size: 11px;
            margin-top: 5px;
            font-weight: 300;
            text-transform: uppercase;
            color: #949494;
            margin-bottom: -2px;
          }
          .downArrow-svg {
            margin-left: 8px;
          }
          .downArrow {
            fill: ${colors.mid};
          }
          .CustomerSwitcher-dropdown {
            opacity: 0;
            background: #fff;
            ${effects.shadow1}
            position: absolute;
            top: 100%;
            right: 5px;
            border: 1px solid ${colors.mid};
            border-radius: 5px 0px 5px 5px;
            position: absolute;
            transform: scale(0);
            transition: all 0.3s;
            transform-origin: top right;
            padding: 10px;
            z-index: 1;
            min-width: 300px;
          }
        `}</style>

        <style global="true" jsx="true">{`
          .CustomerSwitcher:hover .CustomerSwitcher-dropdown {
            opacity: 1;
            transform: scale(1);
          }
        `}</style>
      </div>
    );
  }
}
CustomerSwitcher.propTypes = {};

const mapStateProps = (state) => {
  return {
    groupCustomers: state.customer.groupCustomers,
    customer: state.customer.profile,
  };
};

export default connect(mapStateProps, { switchCustomer })(CustomerSwitcher);

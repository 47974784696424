import React from 'react';
import { fonts, colors } from '../../assets/css/theme';

class Input extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <div className="wrap">
        <input
          type="text"
          className="input"
          value={this.props.value || ''}
          onChange={(e) => {
            this.props.onChange(e.target.value);
          }}
          placeholder={this.props.placeholder}
          style={{ ...this.props.style }}
        />
        <style jsx="true">{`
          .wrap {
            width: 100%;
          }
          .input {
            ${fonts.reg}
            font-size:15px;
            width: 100%;
            display: block;
            padding: 10px 10px;
            border-radius: 3px;
            border: 1px solid ${colors.mid};
            margin: 5px 0px;
          }
        `}</style>
      </div>
    );
  }
}

Input.propTypes = {};

export default Input;

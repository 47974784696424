const INITIAL_STATE = {
  profile: null,
  listener: null,
  profileChecked: false,
};

export default (state = INITIAL_STATE, action) => {
  let attrs;
  switch (action.type) {
    case 'USER_PROFILE_LISTENER_UPDATE':
      return { ...state, listener: action.payload };
    case 'USER_PROFILE_UPDATE':
      return { ...state, profile: action.payload, profileChecked: true };
    case 'USER_LOGGED_OUT':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import isEmail from 'validator/lib/isEmail';
import { colors, fonts } from '../../assets/css/theme';
import { Link } from 'react-router-dom';
import {
  loginWithEmail,
  verifyEmailLinkAndHandleMFA,
} from '../../actions/authAction';
import Logo from '../common/Logo';
import OTP from './OTP';

class EmailSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.updateEmail = this.updateEmail.bind(this);
    this.signInUser = this.signInUser.bind(this);
    this.enterSignIn = this.enterSignIn.bind(this);
    this.state = {
      signInSent: false,
      email: '',
      valid: false,
      error: '',
      working: false,
      mfaRequired: false,
      resolver: null,
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {
    let that = this;
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      //load email
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      this.props
        .verifyEmailLinkAndHandleMFA(email, window.location.href)
        .then((result) => {
          if (result.requiresMFA) {
            //console.log('MFA is required. Redirecting to OTP screen...');
            this.setState({
              mfaRequired: true,
              resolver: result.resolver,
            });
          } else {
            //'No MFA required. Redirecting to dashboard...');
            window.localStorage.removeItem('emailForSignIn');
            window.history.pushState({}, 'standard', '/');
          }
        })
        .catch((error) => {
          console.error('Error during email link login:', error);
          this.setState({ error: error.message });
        });
    }
  }

  updateEmail(str) {
    this.setState({
      email: str.target.value,
      valid: isEmail(str.target.value),
    });
  }

  enterSignIn(e) {
    if (e.key === 'Enter' && this.state.valid) {
      this.signInUser();
    }
  }
  async signInUser() {
    this.setState({ working: true, error: '', signInSent: false });
    try {
      const result = await this.props.loginWithEmail(this.state.email);

      //'Result from loginWithEmail:', result);

      // Check if MFA is required
      if (result.requiresMFA) {
        //console.log('MFA is required. MFA info:', result.mfaInfo);

        this.setState({
          mfaRequired: true,
          resolver: result.resolver,
        });
      } else {
        this.setState({ working: false, error: '', signInSent: true });
      }
    } catch (e) {
      console.error('Error during login:', e);
      this.setState({ working: false, error: e.message, signInSent: false });
    }
  }

  render() {
    if (this.state.mfaRequired && this.state.resolver) {
      // console.log(
      //   'Redirecting to OTP screen with resolver:',
      //   this.state.resolver
      // );

      return <OTP resolver={this.state.resolver} />;
    }

    if (this.state.signInSent) {
      return (
        <div className="SignIn">
          <Logo width={312} height={106} />
          <p className="message">
            An email has been sent to {this.state.email} with a special link to
            log you in.
          </p>
          <style jsx="true">{`
            .message {
              ${fonts.reg}
              text-align: center;
              color: #fff;
              max-width: 450px;
              margin: auto;
            }

            .logo {
              width: 312px;
              height: 106px;
              display: block;
              margin: auto;
              margin-bottom: 20px;
            }
          `}</style>
        </div>
      );
    }

    return (
      <div className="SignIn" style={{ backgroundColor: colors.loBlue }}>
        <Logo width={312} height={106} />
        <p className="title">Customer Order Portal</p>
        <p className="message">
          Please login using your <b>official work email address</b>.
        </p>
        <div className="form">
          <input
            type="text"
            className="email"
            onChange={this.updateEmail}
            value={this.state.email}
            onKeyPress={this.enterSignIn}
          />
          <button
            onClick={this.signInUser}
            className="button"
            disabled={!this.state.valid}
          >
            Log In
          </button>
        </div>

        <p className="bottom-links">
          Having trouble logging in?{' '}
          <Link
            to="/signin-password"
            style={{ color: '#9fc9ff', textDecoration: 'underline' }}
          >
            Sign in with your username and password instead.
          </Link>
        </p>

        {this.state.error ? <p className="error">{this.state.error}</p> : null}

        <style jsx="true">{`
          .SignIn {
            max-width: 650px;
            margin: 10px;
            text-align: center;
          }

          .SignIn .error {
            ${fonts.bold}
            text-align: center;
            color: ${colors.orange};
            margin-top: 10px;
          }

          .SignIn .logo {
            width: 312px;
            height: 106px;
            display: block;
            margin: auto;
            margin-bottom: 20px;
          }

          .SignIn .form {
            display: flex;
            max-width: 360px;
            margin: auto;
          }
          .SignIn .email {
            ${fonts.reg}
            flex:1;
            height: 50px;
            color: ${colors.dark};
            padding: 0px 14px;
            font-size: 14px;
            border: none;
            border-radius: 5px 0px 0px 5px;
          }
          .SignIn .button {
            background: ${colors.midBlue};
            color: #fff;
            ${fonts.bold}
            font-size:14px;
            padding: 0px 14px;
            border: none;
            border-radius: 0px 5px 5px 0px;
          }
          .SignIn .button:disabled {
            background: ${colors.mid};
          }

          .SignIn p {
            color: #fff;
          }
          .SignIn p.error {
            font-weight: 700;
            color: ${colors.error};
            margin-top: 10px;
          }
          .SignIn h1 {
            color: ${colors.primary};
          }

          .bottom-links {
            ${fonts.reg}
            color: #9fc9ff;
            cursor: pointer;
            margin-top: 20px;
            font-size: 14px;
          }
        `}</style>
        <style global="true" jsx="true">{`
          .SignIn .title {
            ${fonts.reg}
            color: #fff;
            text-align: center;
            font-size: 35px;
            margin-bottom: 80px;
          }

          .SignIn .message {
            ${fonts.reg}
            text-align: center;
            color: #fff;
          }

          .SignIn .message b {
            ${fonts.bold}
          }
        `}</style>
      </div>
    );
  }
}

EmailSignIn.propTypes = {};

const mapStateProps = (state) => {
  return {
    authedUser: state.auth.authedUser,
  };
};

export default connect(mapStateProps, {
  loginWithEmail,
  verifyEmailLinkAndHandleMFA,
})(EmailSignIn);

import React from 'react';
import { connect } from 'react-redux';
import { effects, fonts, colors } from '../../assets/css/theme';
import Fuse from 'fuse.js';
import SearchBox from '../common/SearchBox';
import ColTitle from '../common/ColTitle';
import PaginationControls from '../common/PaginationControls';
import OrderStepper from './../common/OrderStepper';
import Order from './../common/Order';
import RequestProductAccessButton from '../common/RequestProductAccessButton';
import Favourite from '../common/Favourite';
import ShowFavouritesButton from '../common/ShowFavouritesButton';
import ProductFilter from './../common/ProductFilter';

class Catalogue extends React.Component {
  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this);
    this.renderCatalogue = this.renderCatalogue.bind(this);
    this.renderOrder = this.renderOrder.bind(this);
    this.renderCatalogueRows = this.renderCatalogueRows.bind(this);
    this.renderCatalogueHeader = this.renderCatalogueHeader.bind(this);
    this.setInternals = this.setInternals.bind(this);
    this.state = {
      mode: 'catalogue',
      filterText: '',
      favourites: false,
      paginationCount: 20,
      currentPage: 0,
      totalPages: 1,
      records: [],
      paginationPages: [],
      productGroupsFilters: {
        overLabelledFilter: {
          enabled: true,
          groups: ['MTOG', 'MTOE'],
          displayedHeaderName: 'Bespoke Over Label',
          displayedNameCatalogue: 'Bespoke Over Label',
        },
        bespokeOverLabelled: {
          enabled: true,
          groups: ['GOL', 'EOL'],
          displayedHeaderName: 'Generic Over Label',
          displayedNameCatalogue: 'Generic Over Label',
        },
        Kits: {
          enabled: true,
          groups: ['Bricks', 'Kits'],
          displayedHeaderName: 'Bricks and Kits',
          displayedNameCatalogue: 'Kit',
        },
        NONP: {
          enabled: true,
          groups: ['NONP'],
          displayedHeaderName: 'Other Products',
          displayedNameCatalogue: 'Others',
        },
        ARK: {
          enabled: true,
          groups: ['ARK'],
          displayedHeaderName: 'Ark Kits',
          displayedNameCatalogue: 'Ark',
        },
        ULM: {
          enabled: true,
          groups: ['ULM'],
          displayedHeaderName: 'Unlicensed Medicines',
          displayedNameCatalogue: 'Unlicensed',
        },
        'Original Product': {
          enabled: true,
          groups: ['Original Product'],
          displayedHeaderName: 'Pharmaceutical Products',
          displayedNameCatalogue: 'General Pharma',
        },
      },
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.setInternals(this.props);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setInternals(newProps);
  }

  setInternals(props) {
    let records = props.items.slice().sort((a, b) => {
      if (a.ItemCode in this.props.prices) {
        return -1;
      }

      return 0;
    });

    // window.history.pushState({}, "standard", "/");

    if (window.location.search === '?cart') {
      this.setState({
        mode: 'cart',
      });
      window.history.pushState({}, 'standard', '/catalogue');
    }

    if (this.state.filterText) {
      const fuse = new Fuse(records, {
        shouldSort: true,
        tokenize: false,
        threshold: 0.2,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['ItemCode', 'ItemName'],
      });
      records = fuse.search(this.state.filterText);
    } else {
      //apply other filters when not searching
      if (this.state.favourites) {
        if (this.props.profile && this.props.profile.favourites) {
          records = records.filter((r) => {
            return this.props.profile.favourites.indexOf(r.ItemCode) > -1;
          });
        }
      }

      let groupsDisabled = [];

      Object.entries(this.state.productGroupsFilters).forEach((groupFilter) => {
        // Get values for each productGroupsFilters keys
        if (!groupFilter[1].enabled) {
          // Add Product Groups that will be filtered from the catalog list
          groupsDisabled.push(...groupFilter[1].groups);
        }
      });

      if (groupsDisabled.length !== 0) {
        records = records.filter((r) => {
          // Only display the products in the catalogue that were not found in groupsDisabled
          return groupsDisabled.indexOf(r.Group) === -1;
        });
      }
      // group.GOL
    }

    this.setState({
      records: records,
      totalPages: Math.ceil(records.length / this.state.paginationCount),
    });
  }

  renderCatalogueHeader() {
    return (
      <div className="item">
        <div className="code">
          <ColTitle>Code</ColTitle>
        </div>
        <div className="gtin">
          <ColTitle>GTIN</ColTitle>
        </div>
        <div className="title">
          <ColTitle>Product Name</ColTitle>
        </div>
        <div className="price">
          <ColTitle>Unit Price</ColTitle>
        </div>
        <div className="orderTag">
          <ColTitle>Order Quantity</ColTitle>
        </div>
        <div className="lineTotal">
          <ColTitle>Line Total</ColTitle>
        </div>
        <style>{`
                    .item {
                        display: flex;
                        ${fonts.reg}
                        font-size:14px;
                        padding:10px 0px;
                        align-items: center;
                        border-bottom:1px solid ${colors.mid};
                        background: #fff;
                    }
                    .code {
                        width: 100px;
                    } 
                    .gtin {
                        width:130px;
                        text-align: center;
                    }
                    .title {
                        flex:1;
                    }
                    .type {
                        ${fonts.bold}
                        font-size:12px;
                        color:${colors.mid};
                    }
                    .name {
                        ${fonts.bold}
                        font-size:16px;
                        
                    }
                    .orderTag {
                        width: 120px;
                        text-align: center;
                    }
                    .price {
                                width:90px;
                                ${fonts.bold}
                                font-size:16px;
                                text-align: center;
                            }  
                    .lineTotal {
                        width:90px;
                        ${fonts.bold}
                        font-size:16px;
                        text-align: center;
                    }  
                    @media screen and (max-width:767px){
                        .item {
                            display: none;
                        }
                    }
                    
                    
                   
                `}</style>
      </div>
    );
  }

  updateFilter(filterVal) {
    let attrs = {
      filterText: filterVal,
    };
    if (filterVal) {
      attrs.favourites = false;
      attrs.overLabelledFilter = true;
      attrs.medicalProductFilter = true;
    }
    this.setState(attrs, () => {
      this.setInternals(this.props);
    });
  }

  updateProductFilter(key, val) {
    let attrs = {
      productGroupsFilters: {
        ...this.state.productGroupsFilters,
        [key]: {
          ...this.state.productGroupsFilters[key],
          enabled: val,
        },
      },
    };

    this.setState(attrs, () => {
      this.setInternals(this.props);
    });
  }

  renderCatalogueRows() {
    const getPrice = (i) => {
      if (!this.props.prices[i.ItemCode]) return '';
      return `£${this.props.prices[i.ItemCode].Price.toFixed(2)}`;
    };
    const getOrderPicker = (i) => {
      if (!this.props.prices[i.ItemCode]) return '';
      return <OrderStepper itemCode={i.ItemCode} />;
    };

    const getLineTotal = (i) => {
      if (this.props.cartItemsByKey[i.ItemCode]) {
        return '£' + this.props.cartItemsByKey[i.ItemCode].LineTotal.toFixed(2);
      } else {
        return ' ';
      }
      // if(!this.props.prices[i.ItemCode]) return ''; // FIXME: Unreachable conditions
      // return '£1.00'
    };

    const getLineControls = (i) => {
      if (this.props.prices[i.ItemCode]) {
        return (
          <div className="controls">
            <div className="price">{getPrice(i)}</div>
            <div className="orderTag">{getOrderPicker(i)}</div>
            <div className="lineTotal">{getLineTotal(i)}</div>
            <style jsx>{`
              .controls {
                display: flex;
                align-items: center;
              }
              .price {
                width: 90px;
                ${fonts.bold}
                text-align: center;
              }
              .orderTag {
                width: 120px;
              }
              .lineTotal {
                width: 90px;
                ${fonts.bold}
                text-align: center;
              }
              @media screen and (max-width: 767px) {
                .controls {
                  flex-wrap: wrap;
                  width: 100%;
                  justify-content: space-between;
                }
                .price {
                  width: 100%;
                  text-align: left;
                  margin-bottom: 10px;
                }
                .lineTotal {
                  width: auto;
                  text-align: right;
                  flex: 1;
                  font-size: 22px;
                }
              }
            `}</style>
          </div>
        );
      } else {
        return <RequestProductAccessButton product={i} />;
      }
    };

    return this.state.records
      .filter((i, idx) => {
        //pagination filter
        if (
          idx >
          this.state.currentPage * this.state.paginationCount +
            (this.state.paginationCount - 1)
        )
          return false;
        if (idx < this.state.currentPage * this.state.paginationCount)
          return false;
        return true;
      })
      .sort((a, b) => {
        if (a.ItemCode in this.props.prices) {
          return -1;
        }

        return 0;
      })
      .map((i, idx) => {
        // When filtering the orders by searching the items are
        // returned with a wrapping object and the actual data is
        // inside the "item" key. This is a quick fix and should
        // probably be fixed at the root but that may break other
        // parts of the site.
        i = typeof i.ItemCode === 'undefined' ? i.item : i;

        let groupKey;

        if (
          this.state.productGroupsFilters.overLabelledFilter.groups.indexOf(
            i.Group
          ) > -1
        ) {
          groupKey = 'overLabelledFilter';
        } else if (
          this.state.productGroupsFilters.bespokeOverLabelled.groups.indexOf(
            i.Group
          ) > -1
        ) {
          groupKey = 'bespokeOverLabelled';
        } else {
          groupKey = i.Group;
        }

        let displayedNameGroup;

        if (this.state.productGroupsFilters[groupKey]) {
          displayedNameGroup =
            this.state.productGroupsFilters[groupKey].displayedNameCatalogue;
        }

        return (
          <div className="item" key={i.ItemCode}>
            <div className="code">{i.ItemCode}</div>
            <div className="gtin">{i.GTIN}</div>
            <div className="title">
              <Favourite itemCode={i.ItemCode}></Favourite>

              <div className="type">{displayedNameGroup}</div>

              <div className="name">{i.ItemName}</div>
            </div>
            {getLineControls(i)}
            <style jsx>{`
              .item {
                display: flex;
                ${fonts.reg}
                font-size:14px;
                padding: 10px 0px;
                align-items: center;
                border-bottom: 1px solid ${colors.mid};
                background: #fff;
              }
              .code {
                width: 100px;
              }
              .gtin {
                width: 130px;
                text-align: center;
              }
              .title {
                flex: 1;
                position: relative;
                padding-left: 30px;
              }
              .type {
                ${fonts.bold}
                font-size:12px;
                color: ${colors.mid};
              }
              .name {
                ${fonts.bold}
              }
              @media screen and (max-width: 767px) {
                .item {
                  flex-wrap: wrap;
                }
                .code,
                .gtin {
                  width: 100%;
                }
                .title {
                  padding-left: 0px;
                }
              }
            `}</style>
          </div>
        );
      });
  }

  renderCatalogue() {
    return (
      <div className="catalogue">
        <div className="filters">
          <ProductFilter
            filters={this.state.productGroupsFilters}
            switchShowGroup={(groupKey, groupEnabledValue) => {
              this.updateProductFilter(groupKey, groupEnabledValue);
            }}
          />
          <ShowFavouritesButton
            active={this.state.favourites}
            onClick={() => {
              this.setState(
                { favourites: !this.state.favourites, currentPage: 0 },
                () => {
                  this.setInternals(this.props);
                }
              );
            }}
          />
        </div>

        {this.renderCatalogueHeader()}
        {this.renderCatalogueRows()}
        <PaginationControls
          style={{ marginTop: 20 }}
          totalPages={this.state.totalPages}
          currentPage={this.state.currentPage}
          onChange={(page) => {
            this.setState({ currentPage: page });
          }}
        />

        <style jsx="true">{`
          .filters {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          }
          @media screen and (max-width: 767px) {
            .filters {
              display: block;
            }
          }
        `}</style>
      </div>
    );
  }
  renderOrder() {
    return (
      <div className="order">
        <Order
          order={this.props.cart}
          onOrderPlaced={() => {
            this.setState({ mode: 'catalogue' });
          }}
          editable={true}
        />
      </div>
    );
  }
  renderContent() {
    if (this.state.mode === 'catalogue') {
      return this.renderCatalogue();
    } else {
      return this.renderOrder();
    }
  }
  render() {
    const renderCurrentOrderTab = () => {
      if (!this.props.cartTotal) return null;
      return (
        <div
          className={
            this.state.mode === 'cart'
              ? 'currentOrder tab active'
              : 'currentOrder tab'
          }
          onClick={() => this.setState({ mode: 'cart' })}
        >
          Current Order: £{this.props.cart.Total.toFixed(2)}
          <img
            className="currentOrder-img"
            src={require('./../../assets/img/follow.svg').default}
            alt=""
          />
          <style jsx="true">{`
            .item {
              display: flex;
              ${fonts.reg}
              font-size:14px;
              padding: 10px 0px;
              align-items: center;
              border-bottom: 1px solid ${colors.mid};
              background: #fff;
            }
            .currentOrder {
              display: flex;
              ${fonts.bold}
              color:${colors.midBlue};
              align-items: center;
              padding-left: 10px;
              font-size: 12px;
              padding-right: 10px;
              cursor: pointer;
              text-transform: uppercase;
            }
            .currentOrder:hover .currentOrder-img {
              transform: scale(1.2);
            }
            .currentOrder-img {
              width: 20px;
              height: 20px;
              margin-left: 5px;
              transition: all 0.3s;
            }
            .code {
              width: 100px;
            }
            .gtin {
              width: 130px;
              text-align: center;
            }
            .title {
              flex: 1;
              position: relative;
              padding-left: 30px;
            }
            .type {
              ${fonts.bold}
              font-size:12px;
              color: ${colors.mid};
            }
            .name {
              ${fonts.bold}
            }
            @media screen and (max-width: 767px) {
              .item {
                flex-wrap: wrap;
              }
              .code,
              .gtin {
                width: 100%;
              }
              .title {
                padding-left: 0px;
              }
            }
          `}</style>
        </div>
      );
    };

    const renderTabs = () => {
      return (
        <div className="tabs">
          <div
            className={
              this.state.mode === 'catalogue' ? 'tab first active' : 'tab'
            }
            onClick={() => this.setState({ mode: 'catalogue' })}
          >
            Catalogue
          </div>
          {renderCurrentOrderTab()}
          <div className="search-area">
            <SearchBox
              placeholder="Search by name or product code"
              value={this.state.filterText}
              onChange={(val) => {
                this.updateFilter(val);
              }}
            />
          </div>
          <style jsx>{`
            .tabs {
              display: flex;
              position: relative;
            }
            .search-area {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
            .search-box {
              padding: 5px;
              border: 1px solid ${colors.mid};
              width: 300px;
              border-radius: 8px 10px;
              font-size: 14px;
              text-align: right;
              color: ${colors.dark};
              ${effects.shadow1}
            }
            .tab {
              background: ${colors.loBlue};
              width: 220px;
              height: 45px;
              padding: 0px 10px 0px 20px;
              display: flex;
              align-items: center;
              margin-right: 5px;
              margin-bottom: 0px;
              border-radius: 2px 30px 0px 0px;
              ${effects.tabShadow}
              ${fonts.bold}
                            font-size:16px;
              color: #fff;
              justify-content: space-between;
              cursor: pointer;
              z-index: 0;
            }
            .tab.active {
              background: #fff;
              color: ${colors.midBlue};
              cursor: default;
              position: relative;
              ${effects.shadow1}
              z-index: 1;
            }
            .tab:after {
              content: '';
              position: absolute;
              bottom: -10px;
              left: 0px;
              right: 0px;
              display: block;
              height: 10px;
              background: #fff;
            }
            @media screen and (max-width: 767px) {
              .search-area {
                display: none;
              }
            }
          `}</style>
        </div>
      );
    };
    return (
      <div className="Catalogue">
        {renderTabs()}
        <div className="Catalogue-wrap">{this.renderContent()}</div>
        <style jsx>{`
          .Catalogue-wrap {
            padding: 20px;
            background: #fff;
            ${effects.shadow1}
            margin-top:0px;
            min-height: 70vh;
          }
        `}</style>
      </div>
    );
  }
}

Catalogue.propTypes = {};

const mapStateProps = (state) => {
  return {
    items: state.customer.items,
    prices: state.customer.priceList,
    cartItemsByKey: state.cart.itemsByKey,
    cartTotal: state.cart.cart.Total,
    cart: state.cart.cart,
    profile: state.profile.profile,
  };
};

export default connect(mapStateProps, {})(Catalogue);

import firebase from 'firebase/app';
import 'firebase/auth';
import reauthenticateUser from './reauthenticateUser';

const disable2FA = async (
  setMfaEnabled,
  setPhoneNumber,
  setIsReauthenticating
) => {
  try {
    const user = firebase.auth().currentUser;

    if (!user) {
      throw new Error('No authenticated user found.');
    }

    setIsReauthenticating(true);

    // Ensure RecaptchaVerifier exists before reauthenticating
    if (!window.recaptchaVerifier) {
      console.warn('RecaptchaVerifier is missing, reinitializing...');
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => console.log('reCAPTCHA solved:', response),
        }
      );
    }

    // Reauthenticate user before removing 2FA
    await reauthenticateUser(() => {}, setIsReauthenticating, 'disableMFA');

    // Get multi-factor enrolled factors
    const enrolledFactors = user.multiFactor.enrolledFactors;
    if (enrolledFactors.length === 0) {
      alert('No 2FA methods to remove.');
      return;
    }

    // Unenroll the first (phone) factor
    await user.multiFactor.unenroll(enrolledFactors[0].uid);

    console.log('2FA disabled successfully.');

    // Update UI state
    setMfaEnabled(false);
    setPhoneNumber('');

    alert('Two-Factor Authentication has been disabled.');
  } catch (error) {
    console.error('Error disabling 2FA:', error);
    alert(error.message || 'Failed to disable 2FA.');
  } finally {
    setIsReauthenticating(false);
  }
};

export default disable2FA;

import firebase from 'firebase/app';
import 'firebase/auth';

// Login with magic link sent to email
export const loginWithEmail = (email) => {
  let url = `${window.location.protocol}//${window.location.host}/`;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: url,
        // This must be true.
        handleCodeInApp: true,
        iOS: {
          bundleId: 'com.example.ios',
        },
        android: {
          packageName: 'com.example.android',
          installApp: true,
          minimumVersion: '12',
        },
      };

      firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function () {
          window.localStorage.setItem('emailForSignIn', email);
          resolve(true);
        })
        .catch(function (error) {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const verifyEmailLinkAndHandleMFA = (email, link) => {
  return async (dispatch) => {
    try {
      const result = await firebase.auth().signInWithEmailLink(email, link);

      // Check for MFA requirement
      if (
        result.user.multiFactor &&
        result.user.multiFactor.enrolledFactors.length > 0
      ) {
        const resolver = firebase.auth().getMultiFactorResolver(result);

        return {
          requiresMFA: true,
          resolver,
        };
      } else {
        // Dispatch successful login if no MFA is required
        dispatch({
          type: 'USER_LOGGED_IN',
          payload: result.user,
        });
        return { requiresMFA: false };
      }
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = error.resolver;
        return { requiresMFA: true, resolver };
      }
      throw new Error(error.message || 'Login failed');
    }
  };
};

// Login with username and password
export const loginWithEmailPassword = (email, password) => {
  return async (dispatch) => {
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      dispatch({
        type: 'USER_LOGGED_IN',
        payload: userCredential.user,
      });

      return { requiresMFA: false };
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = error.resolver;
        return { requiresMFA: true, resolver };
      } else {
        throw new Error(error.message || 'Login failed');
      }
    }
  };
};

// Verify OTP (One Time Password) code - Used by MFA (Multi-Factor Authentication) via SMS
export const verifyOTP = (resolver, verificationId, otpCode) => {
  return async (dispatch) => {
    try {
      //'verificationId:', verificationId, 'otpCode:', otpCode);

      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        otpCode
      );

      const multiFactorAssertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(credential);

      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);

      const user = firebase.auth().currentUser;

      if (!user) {
        throw new Error('Authentication failed after OTP verification.');
      }

      //console.log('User authenticated:', user);

      //console.log('OTP Verified. Dispatching USER_LOGGED_IN...');

      dispatch({
        type: 'USER_LOGGED_IN',
        payload: userCredential.user,
      });

      return true;
    } catch (error) {
      //console.error('Error in verifyOTP:', error);
      throw error;
    }
  };
};

export const resendOTP = (resolver) => {
  return async () => {
    //console.log('Resolver definition', resolver);
    try {
      // First enrolled MFA factor
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      // Reuse the same RecaptchaVerifier
      const appVerifier = window.recaptchaVerifier;

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        appVerifier
      );

      // Return the new verification ID
      return verificationId;
    } catch (error) {
      //console.error('Failed to resend OTP:', error);
      throw new Error('Failed to resend OTP.');
    }
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch({
      type: 'AUTH_WORKING',
    });

    firebase
      .auth()
      .signOut()
      .then(
        () => {
          //console.log('sign user out');
          dispatch({
            type: 'USER_LOGGED_OUT',
          });
        },
        (err) => {
          //console.log('sign out error', err);
        }
      );
  };
};

export const setAuthFlow = (step) => ({
  type: 'SET_AUTH_FLOW',
  payload: step,
});

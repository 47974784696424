import React from 'react';
import { colors, fonts } from '../../assets/css/theme';

let maxPages = 3;

class PaginationControls extends React.Component {
  constructor(props) {
    super(props);
    this.setInternals = this.setInternals.bind(this);
    this.state = {
      pages: [],
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.setInternals(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setInternals(newProps);
  }

  setInternals(props) {
    let pages = [];
    for (let i = 0; i < props.totalPages; i++) {
      pages.push(i);
    }

    this.setState({
      pages: pages,
    });
  }
  render() {
    if (this.props.totalPages < 2) return null;

    const renderPages = () => {
      let paginated;

      if (this.props.currentPage === 0) {
        paginated = this.state.pages.slice(0, maxPages);
      } else {
        paginated = this.state.pages.slice(
          this.props.currentPage - 1,
          this.props.currentPage - 1 + maxPages
        );
        if (paginated.length < maxPages) {
          paginated = this.state.pages.slice(
            this.props.currentPage - 1,
            this.props.totalPages
          );
        }
      }

      return paginated.map((p, idx) => {
        return (
          <span
            key={idx}
            onClick={() => {
              this.props.onChange(p);
            }}
            className={
              this.props.currentPage === p ? 'pagination active' : 'pagination'
            }
          >
            {p + 1}
          </span>
        );
      });
    };

    const renderHome = () => {
      if (this.props.totalPages <= maxPages) return null;
      return (
        <span
          onClick={() => {
            this.props.onChange(0);
          }}
          className={
            this.props.currentPage === 0 ? 'pagination disabled' : 'pagination'
          }
        >{`<`}</span>
      );
    };

    const renderLast = () => {
      if (this.props.totalPages <= maxPages) return null;
      return (
        <span
          onClick={() => {
            this.props.onChange(this.props.totalPages - 1);
          }}
          className={
            this.props.currentPage === this.props.totalPages - 1
              ? 'pagination disabled'
              : 'pagination'
          }
        >{`>`}</span>
      );
    };
    return (
      <div className="PaginationControls" style={{ ...this.props.style }}>
        {renderHome()}
        {renderPages()}
        {renderLast()}

        <style global="true" jsx="true">{`
          .PaginationControls {
            display: flex;
          }
          .PaginationControls span {
            width: 50px;
            padding: 8px;
            background: ${colors.mid};
            text-align: center;
            border-right: 1px solid #fff;
            cursor: pointer;
            ${fonts.bold}
            font-size:12px;
          }
          .PaginationControls span:hover {
            background: ${colors.hiBlue};
          }
          .PaginationControls span.active {
            background: ${colors.midBlue};
            color: #fff;
            cursor: default;
          }
          .PaginationControls span.pagination.disabled {
            opacity: 0.5;
          }

          .PaginationControls span.pagination.disabled:hover {
            background: ${colors.mid};
            cursor: default;
          }
        `}</style>
      </div>
    );
  }
}

PaginationControls.propTypes = {};

export default PaginationControls;

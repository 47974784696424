import _ from 'lodash';

const INITIAL_STATE = {
  cart: {},
  lineItems: [],
  itemsByKey: {},
  cartTotal: 0,
};

export default (state = INITIAL_STATE, action) => {
  let attrs = {};
  switch (action.type) {
    case 'USER_CART_UPDATE':
      attrs.cart = action.payload || {};
      attrs.itemsByKey = {};
      attrs.cartTotal = 0;
      if (
        action.payload &&
        action.payload.Lines &&
        action.payload.Lines.length
      ) {
        action.payload.Lines.map((line) => {
          attrs.itemsByKey[line.ItemCode] = line;
          attrs.cartTotal += line.LineTotal;
        });

        attrs.lineItems =
          _.sortBy(action.payload.lineItems, 'LineNumber') || [];
      }
      return { ...state, ...attrs };
    case 'USER_LOGGED_OUT':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

import firebase from 'firebase/app';
import 'firebase/auth';
import reauthenticateUser from './reauthenticateUser';

const setPasswordForUser = async (newPassword, setError, setSuccess) => {
  try {
    const user = firebase.auth().currentUser;

    if (!user) {
      console.error('No authenticated user found.');
      return false;
    }

    // If the user has a password, require reauthentication before update
    // const providerData = user.providerData.find(
    //   (provider) => provider.providerId === 'password'
    // );

    const signInMethods = await firebase
      .auth()
      .fetchSignInMethodsForEmail(user.email);
    const hasPasswordProvider = signInMethods.includes('password');

    if (hasPasswordProvider) {
      // console.log('User already has a password. Reauthentication required.');
      try {
        await reauthenticateUser(
          () => {},
          () => {}
        );
      } catch (error) {
        console.error('Error reauthenticating user:', error);
        return false;
      }
    } else {
      // console.log('User does not have a password. Proceeding with update.');
    }

    await user.updatePassword(newPassword);
    // console.log('Password set successfully.');

    await user.reload();

    setSuccess('Password set successfully!');
    setError(null);

    return true;
  } catch (error) {
    console.error('Error setting password:', error);

    const errorMessages = {
      'auth/wrong-password': 'Incorrect password. Please try again.',
      'auth/requires-recent-login':
        '⚠️ For security, please reauthenticate before updating your password.',
      'auth/network-request-failed':
        'Network error. Check your connection and try again.',
    };

    setError(
      errorMessages[error.code] || error.message || 'Something went wrong.'
    );
    return false;
  }
};

export default setPasswordForUser;

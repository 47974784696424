import React from 'react';
import { connect } from 'react-redux';
import Order from '../common/Order';
import { effects } from '../../assets/css/theme';
import withRouter from '../../util/withRouter';

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.setInternals = this.setInternals.bind(this);
    this.state = {
      order: null,
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.setInternals(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setInternals(newProps);
  }

  setInternals(props) {
    let id = props.router.params.id;
    let order = null;

    props.invoices.forEach((i) => {
      if (i.id === id) {
        order = i;
      }
    });

    this.setState({
      order: order,
    });

    if (order) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  render() {
    return (
      <div className="Invoice">
        <Order order={this.state.order} title="Invoice" />
        <style jsx="true">{`
          .Invoice {
            min-height: 70vh;
            padding: 30px 30px;
            background: #fff;
            ${effects.shadow1}
          }
          @media print {
            .Invoice {
              min-height: auto;
              padding: 0px;
              box-shadow: none;
            }
          }
        `}</style>
      </div>
    );
  }
}

Invoice.propTypes = {};

const mapStateProps = (state) => {
  return {
    invoices: state.customer.invoices,
  };
};

export default withRouter(connect(mapStateProps, {})(Invoice));

const INITIAL_STATE = {
  authedUser: null,
  authChecked: false,
  permissionsLoaded: false,
  permissions: {},
  enabledForCustomer: false,
  enabledForCustomerChecked: false,
  authFlow: localStorage.getItem('authFlow') || 'login',
};

// Helper to determine the correct authFlow value
const resolveAuthFlowFromStorage = () => {
  const storedFlow = localStorage.getItem('authFlow');
  if (storedFlow === 'otp' || storedFlow === 'phone-registration') {
    return storedFlow;
  }
  return 'dashboard';
};

export default (state = INITIAL_STATE, action) => {
  let attrs;
  switch (action.type) {
    case 'FIREBASE_AUTHSTATE_CHANGE':
      return {
        ...state,
        authChecked: true,
        authedUser: action.payload,
        authFlow: action.payload ? resolveAuthFlowFromStorage() : 'login',
      };

    case 'USER_LOGGED_IN':
      console.log('USER_LOGGED_IN action triggered:', action.payload);
      return { ...state, authedUser: action.payload, authFlow: 'dashboard' };

    case 'USER_PERMISSIONS_UPDATE':
      return { ...state, permissions: action.payload, permissionsLoaded: true };

    case 'SET_AUTH_FLOW':
      localStorage.setItem('authFlow', action.payload);
      return { ...state, authFlow: action.payload };

    case 'AUTH_LOGGED_OUT':
      localStorage.removeItem('authFlow');
      return { ...state, authUser: null };

    case 'ENABLED_FOR_CUSTOMER':
      return {
        ...state,
        enabledForCustomer: action.payload,
        enabledForCustomerChecked: true,
      };

    case 'RESET':
      return { ...INITIAL_STATE, connecting: false, connected: true };

    case 'USER_LOGGED_OUT':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

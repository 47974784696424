import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setAuthFlow } from '../../actions/authAction';
import firebase from 'firebase/app';
import 'firebase/auth';
import Logo from '../common/Logo';
import OTP from './OTP';
import { colors, fonts } from '../../assets/css/theme';

const PhoneRegistration = ({ logoutUser, setAuthFlow }) => {
  const [phoneNumber, setPhoneNumber] = useState('+44');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [resolver, setResolver] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('phoneInput');

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      console.log('Initializing ReCAPTCHA...');

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) =>
            console.log('reCAPTCHA solved successfully', response),
          'expired-callback': () => console.error('reCAPTCHA expired'),
        }
      );

      window.recaptchaVerifier.render().then((widgetId) => {
        //console.log('ReCAPTCHA widget ID:', widgetId);
      });
    }
  }, []);

  const handleSendVerificationCode = async () => {
    let normalizedPhoneNumber = phoneNumber.replace(/^(\+4407)/, '+447');

    if (!/^(\+44)7\d{9}$/.test(normalizedPhoneNumber.trim())) {
      setError(
        'Please enter a valid UK mobile number (e.g., +447911123456 or +447360123456).'
      );
      return;
    }

    if (!phoneNumber.trim()) {
      setError('Please enter a valid phone number.');
      return;
    }

    try {
      setLoading(true);
      setError('');

      const user = firebase.auth().currentUser;
      if (!user) {
        throw new Error('No authenticated user found.');
      }

      const session = await user.multiFactor.getSession();

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          phoneNumber: phoneNumber,
          session: session,
        },
        window.recaptchaVerifier
      );

      //console.log('Received verificationId:', verificationId);

      if (!verificationId) {
        throw new Error('No verification ID received.');
      }

      //   setStep('otpVerification');
      localStorage.setItem('verificationId', verificationId); // Store in localStorage
      setVerificationId(verificationId);

      // Generate the resolver object for OTP.js
      const newResolver = {
        session: session,
        hints: [{ phoneNumber: phoneNumber }],
      };

      setResolver(newResolver);
      setStep('otpVerification');
    } catch (err) {
      setError('Failed to send verification code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (step == 'otpVerification') {
    //console.log('Rendering OTP component with verificationId:', verificationId);

    return (
      <OTP
        resolver={resolver}
        verificationId={verificationId}
        isPhoneRegistration={true}
      />
    );
  }

  return (
    <div className="phone-registration">
      <Logo width={312} height={106} />
      <p className="title">Secure Your Account</p>
      <p className="subtitle">
        {step === 'phoneInput'
          ? 'Enter your phone number to enable 2FA.'
          : 'Enter the OTP sent to your phone.'}
      </p>

      <div className="form">
        {step === 'phoneInput' && (
          <>
            <input
              type="tel"
              className="input-field"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <button
              onClick={handleSendVerificationCode}
              className="button"
              disabled={loading || !phoneNumber.trim()}
            >
              {loading ? 'Sending...' : 'Send Code'}
            </button>
          </>
        )}

        {error && <p className="error-message">{error}</p>}
      </div>

      <button className="logout-button" onClick={logoutUser}>
        Log Out
      </button>

      <div id="recaptcha-container"></div>

      <style jsx="true">{`
        .phone-registration {
          max-width: 450px;
          margin: 50px auto;
          text-align: center;
          padding: 40px;
          border-radius: 10px;
          background-color: ${colors.loBlue};
        }

        .title {
          ${fonts.reg}
          font-size: 28px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 10px;
        }

        .subtitle {
          ${fonts.reg}
          font-size: 16px;
          color: #ddd;
          margin-bottom: 30px;
        }

        .form {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .input-field {
          ${fonts.reg}
          height: 50px;
          padding: 0 14px;
          font-size: 16px;
          border: 2px solid #ddd;
          border-radius: 8px;
          background-color: #fff;
          color: ${colors.dark};
        }

        .button {
          ${fonts.reg}
          background-color: ${colors.midBlue};
          color: #fff;
          font-size: 18px;
          padding: 12px;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          font-weight: bold;
          transition: background 0.3s;
        }

        .button:disabled {
          background-color: ${colors.mid};
          cursor: not-allowed;
        }

        .logout-button {
          margin-top: 20px;
          background: none;
          border: none;
          color: #ff7777;
          font-size: 14px;
          cursor: pointer;
          text-decoration: underline;
        }

        .logout-button:hover {
          color: #ff4444;
        }

        .error-message {
          ${fonts.reg}
          color: #ff7777;
          font-size: 14px;
          font-weight: bold;
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default connect(null, { setAuthFlow })(PhoneRegistration);

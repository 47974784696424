import React from 'react';
import { connect } from 'react-redux';
import { effects, fonts, colors } from '../../assets/css/theme';
import Label from '../common/Label';
import Input from '../common/Input';
import Button from '../common/Button';
import { saveProfile } from '../../actions/profileAction';
import LogOutLink from '../common/LogOutLink';

class CreateUserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.updateKey = this.updateKey.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.state = {
      firstName: '',
      lastName: '',
      customerId: '',
      hospitalName: '',
      valid: false,
      working: false,
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}
  async updateKey(key, value) {
    let attrs = {};
    attrs[key] = value;
    try {
      await this.setState({ ...attrs });
      this.setState({
        valid:
          this.state.firstName &&
          this.state.lastName &&
          this.state.customerId &&
          this.state.hospitalName,
      });
    } catch (e) {
      console.error(e);
    }
  }
  async saveProfile() {
    if (this.state.valid && !this.state.working) {
      try {
        this.setState({ working: true });
        await this.props.saveProfile({
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          customerId: this.state.customerId,
          hospitalName: this.state.hospitalName,
        });
      } catch (e) {
        console.error(e);
        this.setState({ working: false });
      }
    }
  }
  render() {
    return (
      <div className="CreateUserProfile">
        <h2 className="title">Thanks for registering with us.</h2>
        <p className="intro">
          So that we can activate your account, please complete details below
          and click on submit
        </p>

        <div className="form">
          <div className="split">
            <div className="splitInputwrap">
              <Label>First Name</Label>
              <Input
                value={this.state.firstName}
                onChange={(val) => {
                  this.updateKey('firstName', val);
                }}
                placeholder="Your first name"
              ></Input>
            </div>
            <div className="splitInputwrap">
              <Label>Last Name</Label>
              <Input
                value={this.state.lastName}
                onChange={(val) => {
                  this.updateKey('lastName', val);
                }}
                placeholder="Your last name"
              ></Input>
            </div>
          </div>

          <div className="inputwrap">
            <Label>Customer Account Number</Label>
            <Input
              value={this.state.customerId}
              onChange={(val) => {
                this.updateKey('customerId', val);
              }}
              placeholder="Customer account number that you wish to purchase for"
            ></Input>
          </div>
          <div className="inputwrap">
            <Label>Hospital Name</Label>
            <Input
              value={this.state.hospitalName}
              onChange={(val) => {
                this.updateKey('hospitalName', val);
              }}
              placeholder="The name of the hospital that you wish to purchase for"
            ></Input>
          </div>

          <div
            className="inputwrap"
            style={{ textAlign: 'center', marginBottom: 0 }}
          >
            <Button
              disabled={!this.state.valid}
              onClick={this.saveProfile}
              working={this.state.working}
            >
              Submit
            </Button>
          </div>
        </div>
        <LogOutLink></LogOutLink>

        <style jsx="true">{`
          .CreateUserProfile {
            max-width: 725px;
            padding: 20px;
          }
          .split {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
          .splitInputwrap {
            margin-bottom: 15px;
            width: calc(50% - 10px);
          }

          .title {
            ${fonts.bold};
            font-size: 26px;
            color: ${colors.loBlue};
            text-align: center;
            margin-bottom: 40px;
          }
          .intro {
            ${fonts.reg};
            font-size: 26px;
            color: ${colors.loBlue};
            text-align: center;
            margin-bottom: 40px;
          }
          .form {
            background: #fff;
            padding: 30px;
            margin: auto;
            border-radius: 5px;
            ${effects.shadow1}
          }
          .inputwrap {
            margin-bottom: 20px;
          }
        `}</style>
      </div>
    );
  }
}

CreateUserProfile.propTypes = {};

const mapStateProps = (state) => {
  return {};
};

export default connect(mapStateProps, { saveProfile })(CreateUserProfile);

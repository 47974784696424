import React, { useState, useRef, useEffect } from 'react';
import { verifyOTP, resendOTP, setAuthFlow } from '../../actions/authAction';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { colors, fonts } from '../../assets/css/theme';
import Logo from '../common/Logo';

const OTP = ({
  resolver,
  verificationId: propVerificationId,
  verifyOTP,
  resendOTP,
  isPhoneRegistration,
}) => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [verificationId, setVerificationId] = useState(
    propVerificationId || localStorage.getItem('verificationId') || null
  );
  const [error, setError] = useState(null);
  const [isResending, setIsResending] = useState(false);
  const [recaptchaInitialized, setRecaptchaInitialized] = useState(false);
  const inputs = useRef([]);

  useEffect(() => {
    // Ensure the reCAPTCHA container exists
    if (!document.getElementById('recaptcha-container')) {
      const recaptchaContainer = document.createElement('div');
      recaptchaContainer.id = 'recaptcha-container';
      document.body.appendChild(recaptchaContainer);
      //console.log('ReCAPTCHA container created.');
    }

    if (!verificationId) {
      console.warn('No verificationId found initially.');
    }

    // Initialize ReCAPTCHA verifier
    if (!recaptchaInitialized) {
      initializeRecaptchaVerifier(() => {
        //console.log('ReCAPTCHA initialized, sending verification code.');
        sendVerificationCode();
      });
    }
  }, [recaptchaInitialized]);

  const initializeRecaptchaVerifier = (callback) => {
    try {
      if (window.recaptchaVerifier) {
        console.warn('ReCAPTCHA verifier already initialized.');
        callback && callback();
        return;
      }

      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            //console.log('ReCAPTCHA solved successfully:', response);
            sendVerificationCode(response);
          },
          'expired-callback': () => {
            console.error('ReCAPTCHA expired.');
            setError('ReCAPTCHA expired. Please refresh and try again.');
          },
        }
      );

      window.recaptchaVerifier.render().then((widgetId) => {
        //console.log('ReCAPTCHA rendered with widget ID:', widgetId);
        setRecaptchaInitialized(true);
        callback && callback();
      });
    } catch (err) {
      console.error('Error initializing reCAPTCHA verifier:', err);
      setError('Failed to initialize reCAPTCHA. Please try again.');
    }
  };

  const getFriendlyErrorMessage = (code) => {
    const errorMessages = {
      'auth/missing-phone-number': 'Please provide a phone number.',
      'auth/invalid-phone-number':
        'The phone number is invalid. Please check the format.',
      'auth/captcha-check-failed': null, // Suppress Errors - 'ReCAPTCHA verification failed. Please try again.',
      'auth/invalid-verification-code':
        'Invalid OTP. Please check the code and try again.',
      'auth/invalid-verification-id':
        'Verification ID is invalid. Please restart the process.',
      'auth/too-many-requests': 'Too many attempts. Please try again later.',
      'auth/invalid-app-credential':
        'ReCAPTCHA token expired or invalid. Please refresh and try again.',
    };

    // console.error('Unknown error code:', code);
    // return 'An unexpected error occurred. Please try again or contact support.';
    return errorMessages[code] || null;
  };

  const sendVerificationCode = async () => {
    try {
      if (!resolver || !resolver.session || !resolver.hints) {
        throw new Error(
          'Session is invalid or expired. Please restart the process.'
        );
      }

      //console.log('Starting phone verification process...');
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };

      // console.log(
      //   'Sending verification code with phone info options:',
      //   phoneInfoOptions
      // );

      const id = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier
      );

      if (!id) {
        throw new Error('Failed to get verification ID. Please try again.');
      }

      //console.log('Verification ID received:', id);
      localStorage.setItem('verificationId', id);
      setVerificationId(id);
    } catch (err) {
      console.error('Error sending verification code:', err);
      setError(getFriendlyErrorMessage(err.code) || '');
    }
  };

  const handleResendCode = async () => {
    if (isResending) return;
    setIsResending(true);

    try {
      const appVerifier = window.recaptchaVerifier;
      let newVerificationId;

      if (isPhoneRegistration) {
        // Use current user to get a fresh multiFactor session for phone registration
        const user = firebase.auth().currentUser;
        if (!user) throw new Error('No authenticated user.');

        const session = await user.multiFactor.getSession();
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
          { phoneNumber: resolver.hints[0]?.phoneNumber, session },
          appVerifier
        );
      } else {
        if (!resolver?.hints?.length) {
          throw new Error('No resolver session found.');
        }
        newVerificationId = await resendOTP(resolver, appVerifier);
      }

      if (!newVerificationId) {
        setError('Verification ID is missing or invalid. ');
        return;
      }

      // console.log('New Verification ID:', newVerificationId);
      localStorage.setItem('verificationId', newVerificationId);
      setVerificationId(newVerificationId);
    } catch (err) {
      console.error('Error resending OTP:', err);
      setError(getFriendlyErrorMessage(err.code) || '');
    } finally {
      setIsResending(false);
    }
  };

  const handleChange = (element, index) => {
    const value = element.value.replace(/\D/, '');
    if (value) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus next input
      if (index < 5) {
        inputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    const newOtp = [...otp];

    if (e.key === 'Backspace') {
      if (otp[index]) {
        // Clear current input
        newOtp[index] = '';
        setOtp(newOtp);
      } else if (index > 0) {
        // Move to the previous input and clear it
        inputs.current[index - 1].focus();
        newOtp[index - 1] = '';
        setOtp(newOtp);
      }
    }

    if (e.key === 'Delete') {
      // Clear the current input
      newOtp[index] = '';
      setOtp(newOtp);

      if (index > 0) {
        // Move to the previous input
        inputs.current[index - 1].focus();
        newOtp[index - 1] = '';
        setOtp(newOtp);
      }
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const otpCode = otp.join('');
      if (otpCode.length !== 6) {
        setError('Please enter a 6-digit OTP.');
        return;
      }

      if (!verificationId) {
        setError('Verification ID is missing. Please resend the code.');
        return;
      }

      //console.log('Verifying OTP:', otpCode);

      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        otpCode
      );

      const user = firebase.auth().currentUser;

      if (isPhoneRegistration) {
        // Enrolling phone number in MFA
        //console.log('Enrolling phone number for MFA...');

        if (!user.multiFactor) {
          setError('Multi-Factor Authentication not supported on this user.');
          return;
        }

        const assertion =
          firebase.auth.PhoneMultiFactorGenerator.assertion(credential);

        await user.multiFactor.enroll(assertion, 'Phone Number');
        //'Phone successfully enrolled for MFA.');
      } else {
        // Normal OTP Sign-in flow
        await verifyOTP(resolver, verificationId, otpCode);
      }

      localStorage.setItem('authFlow', 'dashboard');

      setAuthFlow('dashboard');
      window.location.href = '/';
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setError(err.message || 'Failed to verify OTP.');
    }
  };

  return (
    <div className="otp-wrapper" style={{ backgroundColor: colors.loBlue }}>
      <div id="recaptcha-container"></div>
      <div className="otp-container">
        <Logo width={312} height={106} />
        <p className="instruction">Enter the OTP sent to your phone</p>

        <div className="otp-inputs">
          {otp.map((_, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              ref={(el) => (inputs.current[index] = el)}
              value={otp[index]}
              onChange={(e) => handleChange(e.target, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          ))}
          <button onClick={handleOtpSubmit} className="submit-button">
            ➔
          </button>
        </div>

        <p className="resend-link" onClick={handleResendCode}>
          Resend code to my phone
        </p>

        {error && <p className="error-message">{error}</p>}
      </div>

      <style jsx="true">{`
        .otp-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
        }
        .otp-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 30px;
          border-radius: 10px;
        }
        .instruction {
          ${fonts.reg}
          font-size: 1.2rem;
          color: #9fc9ff;
          margin: 20px 0;
        }
        .otp-inputs {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 0;
        }
        .otp-inputs input {
          width: 40px;
          height: 40px;
          margin: 0 5px;
          text-align: center;
          font-size: 1.5rem;
          border: none;
          border-radius: 5px;
          background-color: #fff;
          color: #143a67;
        }
        .submit-button {
          background-color: #4c8bf5;
          color: #fff;
          font-size: 1.2rem;
          width: 40px;
          height: 40px;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
        .resend-link {
          ${fonts.reg}
          color: #9fc9ff;
          cursor: pointer;
          margin-top: 10px;
        }
        .error-message {
          ${fonts.reg}
          color: #fff;
          font-size: 0.9rem;
          margin-top: 10px;
        }
      `}</style>
    </div>
  );
};

export default connect(null, { verifyOTP, resendOTP })(OTP);

import React from 'react';
import { fonts, colors } from '../../assets/css/theme';
import tickSrc from './../../assets/img/tick.svg';
import { gsap, Elastic } from 'gsap/all';
import LogOutLink from './LogOutLink';

class AwaitingActivation extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {
    gsap.from(this.node, 2, {
      scale: 0.001,
      transformOrigin: '50% 50%',
      ease: Elastic.easeOut,
    });
  }

  render() {
    return (
      <div className="AwaitingActivation">
        <h2 className="title">Thank you.</h2>
        <p className="intro">
          Our team have your details and will be in touch as soon as your
          account is activated
        </p>
        <img
          ref={(node) => (this.node = node)}
          className="tick"
          src={tickSrc}
          alt=""
        />

        <LogOutLink></LogOutLink>
        <style jsx="true">{`
          .AwaitingActivation {
            max-width: 725px;
            padding: 20px;
          }

          .AwaitingActivation .title {
            ${fonts.bold};
            font-size: 26px;
            color: ${colors.loBlue};
            text-align: center;
            margin-bottom: 40px;
          }
          .AwaitingActivation .intro {
            ${fonts.reg};
            font-size: 26px;
            color: ${colors.loBlue};
            text-align: center;
            margin-bottom: 40px;
          }
          .AwaitingActivation .tick {
            width: 205px;
            height: 205px;
            margin: auto;
            display: block;
          }
        `}</style>
      </div>
    );
  }
}

AwaitingActivation.propTypes = {};

export default AwaitingActivation;

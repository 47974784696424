import React from 'react';
import { colors, fonts } from '../../assets/css/theme';

class ToggleOption extends React.Component {
  constructor(props) {
    super(props);
    this.renderIcon = this.renderIcon.bind(this);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  renderIcon(val) {
    if (val) {
      return (
        <svg className="svg" viewBox="0 0 24 24">
          <path
            d="M19.86,4a11.25,11.25,0,1,0,.19.19ZM11.91,21.84a9.93,9.93,0,1,1,9.93-9.93A9.93,9.93,0,0,1,11.91,21.84Z"
            fill={colors.green}
          />
          <path
            d="M17.36,8.39a.65.65,0,0,0-.93,0h0l-5.82,5.82L7.55,11.15a.66.66,0,0,0-.94.93l3.53,3.53a.66.66,0,0,0,.94,0h0l6.28-6.29a.63.63,0,0,0,.05-.91A.06.06,0,0,0,17.36,8.39Z"
            fill={colors.green}
          />
        </svg>
      );
    }
    return (
      <svg className="svg" viewBox="0 0 24 24">
        <path
          data-name="Path 1"
          d="M19.86,4a11.25,11.25,0,1,0,.19.19ZM11.91,21.84a9.93,9.93,0,1,1,9.93-9.93A9.93,9.93,0,0,1,11.91,21.84Z"
          fill={colors.mid}
        />
      </svg>
    );
  }

  render() {
    return (
      <div className="ToggleOption" style={{ ...this.props.style }}>
        <div
          className={this.props.reverse ? 'filter reverse' : 'filter'}
          onClick={this.props.onClick}
        >
          <span className="label">{this.props.label}</span>
          {this.renderIcon(this.props.value)}
        </div>

        <style jsx="true">{`
          .ToggleOption {
            display: flex;
            align-items: center;
          }
          .filter {
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .filter.reverse {
            flex-direction: row-reverse;
          }
          .label {
          }
          .title {
            ${fonts.bold}
            color:${colors.midBlue};
            margin-right: 8px;
          }
        `}</style>

        <style global="true" jsx="true">{`
          .ToggleOption .svg {
            width: 20px;
            height: 20px;
            margin-left: 5px;
            margin-right: 5px;
          }
        `}</style>
      </div>
    );
  }
}

ToggleOption.propTypes = {};

export default ToggleOption;

import React from 'react';
import { colors } from '../../assets/css/theme';

class Status extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    let styles = {};

    if (this.props.children === 'Closed') {
      styles.color = colors.mid;
    }
    if (this.props.children === 'New') {
      styles.color = colors.orange;
    }
    if (this.props.children === 'Open') {
      styles.color = colors.green;
    }

    if (this.props.mode === 'backorders') {
      styles.color = colors.orange;
    }

    const renderText = () => {
      if (this.props.mode === 'orders') {
        switch (this.props.children) {
          case 'New':
            return 'Awaiting Processing';
          case 'Open':
            return 'Processing';
          case 'Back-Ordered':
            return 'To Follow';
          case 'Closed':
            return 'Closed';
          default:
            return this.props.children;
        }
      }

      if (this.props.mode === 'order') {
        switch (this.props.children) {
          case 'Back-Ordered':
            return 'To Follow';

          default:
            return this.props.children;
        }
      }

      if (this.props.mode === 'deliveries') {
        switch (this.props.children) {
          case 'Open':
            return 'Awaiting dispatch';
          case 'Closed':
            return 'Dispatched';
          default:
            return this.props.children;
        }
      }

      if (this.props.mode === 'backorders') {
        switch (this.props.children) {
          case 'Back-Ordered':
            return 'To Follow';

          default:
            return this.props.children;
        }
      }

      return this.props.children;
    };

    return (
      <div className="Status" style={styles}>
        {renderText()}
      </div>
    );
  }
}

Status.propTypes = {};

export default Status;

import React from 'react';
import { effects, colors } from '../../assets/css/theme';
import { gsap } from 'gsap/all';
import MorphSVGPlugin from './../../gsap-bonus/MorphSVGPlugin';

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleInput = this.handleInput.bind(this);
    gsap.registerPlugin(MorphSVGPlugin);

    this.paths = {
      search: {
        shape:
          'M25.51,23.15,21.17,18.8l-.08-.06a8.25,8.25,0,1,0-2.35,2.35l.06.08,4.35,4.34a1.67,1.67,0,0,0,2.36-2.36ZM14.22,19.59a5.37,5.37,0,1,1,5.37-5.37A5.37,5.37,0,0,1,14.22,19.59Z',
        type: 'rotational',
        origin: '50% 50%',
      },
      cancel: {
        shape:
          'M18.14,16l6.42-6.42a1.51,1.51,0,1,0-2.14-2.14L16,13.86,9.58,7.44A1.51,1.51,0,1,0,7.44,9.58L13.86,16,7.44,22.42a1.51,1.51,0,1,0,2.14,2.14L16,18.14l6.42,6.42a1.51,1.51,0,1,0,2.14-2.14Z',
        type: 'rotational',
        origin: '60% 60%',
      },
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  handleClick() {
    if (this.props.value) {
      this.props.onChange('');
      gsap.killTweensOf(this.icon);
      gsap.set(this.icon, { morphSVG: this.paths.search });
    } else {
      this.searchbox.focus();
      gsap.killTweensOf(this.icon);
      gsap.set(this.icon, { morphSVG: this.paths.search });
    }
  }

  handleInput(e) {
    if (e.target.value) {
      this.props.onChange(e.target.value);
      gsap.killTweensOf(this.icon);
      gsap.set(this.icon, { morphSVG: this.paths.cancel });
    } else {
      this.props.onChange('');
      gsap.killTweensOf(this.icon);
      gsap.set(this.icon, { morphSVG: this.paths.search });
    }
  }

  UNSAFE_componentWillReceiveProps() {}
  render() {
    return (
      <div className="SearchBox">
        <input
          ref={(node) => (this.searchbox = node)}
          className="search-box"
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.handleInput}
        />
        <div className="searchControl" onClick={this.handleClick}>
          <svg id="Layer_1" width="20" height="20" viewBox="0 0 32 32">
            <path
              ref={(node) => (this.icon = node)}
              d="M25.51,23.15,21.17,18.8l-.08-.06a8.25,8.25,0,1,0-2.35,2.35l.06.08,4.35,4.34a1.67,1.67,0,0,0,2.36-2.36ZM14.22,19.59a5.37,5.37,0,1,1,5.37-5.37A5.37,5.37,0,0,1,14.22,19.59Z"
            />
          </svg>
        </div>

        <style jsx="true">{`
          .SearchBox {
            display: flex;
            ${effects.shadow1}
            border-radius:4px;
          }
          .search-box {
            padding: 5px;
            border: 1px solid ${colors.mid};
            width: 300px;
            border-radius: 4px 0px 0px 4px;
            font-size: 14px;
            text-align: right;
            color: ${colors.dark};

            height: 32px;
          }
          .searchControl {
            width: 32px;
            background: #fff;
            border-radius: 0px 4px 4px 0px;
            border: 1px solid ${colors.mid};
            border-left: none;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
      </div>
    );
  }
}

SearchBox.propTypes = {};

export default SearchBox;

import React, { useState } from 'react';
import { colors } from '../../assets/css/theme';

const SetOrResetPassword = ({ isReset, setPasswordForUser }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePasswordUpdate = async () => {
    if (!newPassword.trim() || !confirmPassword.trim()) {
      setError('Both fields are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      setLoading(true);
      setSuccess(null);

      const success = await setPasswordForUser(
        newPassword,
        setError,
        setSuccess
      );
      if (!success) return;
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-section">
      <h3>{isReset ? 'Reset Password' : 'Set Password'}</h3>
      <div className="input-group">
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="Enter new password"
          className="styled-input"
        />
      </div>
      <div className="input-group">
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          className="styled-input"
        />
      </div>
      <button
        className="primary-button"
        onClick={handlePasswordUpdate}
        disabled={loading || !newPassword.trim() || !confirmPassword.trim()}
      >
        {loading ? 'Updating...' : isReset ? 'Update Password' : 'Set Password'}
      </button>
      {error && <p className="error-text">{error}</p>}
      {success && <p className="success-text">{success}</p>}

      <style jsx>{`
        .profile-section {
          background: #f9f9f9;
          padding: 20px;
          border-radius: 10px;
          margin-bottom: 20px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
          border-left: 4px solid ${colors.primary};
        }

        .input-group {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
        }

        .styled-input {
          flex: 1;
          padding: 10px;
          border-radius: 6px;
          border: 1px solid #ccc;
          background: #ffffff;
          font-size: 14px;
          transition: border 0.3s ease-in-out;
        }

        .styled-input:focus {
          border-color: ${colors.primary};
          outline: none;
        }

        .primary-button {
          background: ${colors.loBlue};
          color: white;
          margin-top: 10px;
          padding: 10px 16px;
          font-size: 14px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: background 0.3s ease-in-out;
        }

        .primary-button:hover {
          background: ${colors.darkBlue};
        }

        .error-text {
          color: ${colors.error};
          font-size: 13px;
          margin-top: 5px;
        }

        .success-text {
          color: green;
          font-size: 13px;
          margin-top: 5px;
        }
      `}</style>
    </div>
  );
};

export default SetOrResetPassword;

import React from 'react';
import { colors } from '../../assets/css/theme';
import logoSrc from './../../assets/img/logo-blue.svg';
class PopupTitle extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    if (!this.props.children) return null;
    return (
      <div className="popup-title">
        <div className="logo">
          <img className="logoImg" src={logoSrc} alt="" />
        </div>
        {this.props.children}
        <style jsx="true">{`
          .popup-title .logo {
            margin-bottom: 40px;
            display: none;
          }
          .popup-title .logoImg {
            width: 214px;
            height: 72px;
          }
          .popup-title {
            font-weight: 700;
            color: ${colors.midBlue};
            font-size: 24px;
            margin-bottom: 20px;
          }
          @media print {
            .popup-title .logo {
              display: block;
            }
          }
        `}</style>
      </div>
    );
  }
}

PopupTitle.propTypes = {};

export default PopupTitle;

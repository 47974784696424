import React from 'react';
import { connect } from 'react-redux';
import { fonts, colors } from '../../assets/css/theme';
import Input from './Input';

class CustomerRef extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    let valueString = this.props.value;
    if (!this.props.editable && !this.props.value) {
      valueString = 'None given';
    }

    const renderControl = () => {
      if (!this.props.editable) {
        return (
          <p>
            {valueString}
            <style jsx="true">{`
              p {
                ${fonts.bold}
                color : ${colors.midBlue};
                margin: 0;
              }
            `}</style>
          </p>
        );
      } else {
        return (
          <div style={{ width: 200 }}>
            <Input
              value={this.props.value}
              style={{ padding: '5px 10px' }}
              onChange={this.props.onChange}
            ></Input>
          </div>
        );
      }
    };

    return (
      <div className="CustomerRef" style={{ ...this.props.style }}>
        <p className="label">Your Purchase Order Number:</p>
        {renderControl()}
        <style jsx="true">{`
          .CustomerRef {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            margin-top: 20px;
          }
          .label {
            ${fonts.bold}
            margin:0;
            margin-right: 10px;
          }
          @media screen and (max-width: 767px) {
            .CustomerRef {
              margin-top: 0px;
            }
          }
        `}</style>
      </div>
    );
  }
}

CustomerRef.propTypes = {};

const mapStateProps = (state) => {
  return {};
};

export default connect(mapStateProps, {})(CustomerRef);

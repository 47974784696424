import React from 'react';
import { fonts, colors } from '../../assets/css/theme';

class Label extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <div className="Label" style={{ ...this.props.style }}>
        {this.props.children}
        <style jsx="true">{`
          .Label {
            ${fonts.bold}
            color: ${colors.midBlue};
            font-size: 18px;
          }
        `}</style>
      </div>
    );
  }
}

Label.propTypes = {};

export default Label;

import firebase from 'firebase/app';

export const addFavourite = (itemCode) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let user = getState().auth.authedUser;

        let ref = firebase.firestore().collection('users').doc(user.uid);

        await ref.update({
          favourites: firebase.firestore.FieldValue.arrayUnion(itemCode),
        });

        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  };
};

export const removeFavourite = (itemCode) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let user = getState().auth.authedUser;

        let ref = firebase.firestore().collection('users').doc(user.uid);

        await ref.update({
          favourites: firebase.firestore.FieldValue.arrayRemove(itemCode),
        });

        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  };
};

export const requestProductAccess = (item) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        let customer = getState().customer.profile;
        let user = getState().profile.profile;
        let auth = getState().auth.authedUser;

        await firebase
          .firestore()
          .collection('accessRequests')
          .doc()
          .set({
            ItemCode: item.ItemCode,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            requestedBy: user.firstName + ' ' + user.lastName,
            SAPBP: customer.SAPBP,
            userId: auth.uid,
          });
        resolve(true);
      } catch (e) {
        console.error(e);
        reject(e.message);
      }
    });
  };
};

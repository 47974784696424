import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { colors, fonts } from '../../assets/css/theme';
import MaskedPhoneNumber from '../profile/MaskedPhoneNumber';
import disable2FA from '../../util/disable2FA';
import Enable2FA from '../profile/Enable2FA';
import setPasswordForUser from '../../util/setPasswordForUser';
import SetOrResetPassword from '../profile/SetOrResetPassword';
import EditPhoneNumber from '../profile/EditPhoneNumber';

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isReauthenticating, setIsReauthenticating] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);

  useEffect(() => {
    const fetchPasswordStatus = async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        setUser(currentUser);

        try {
          const signInMethods = await firebase
            .auth()
            .fetchSignInMethodsForEmail(currentUser.email);
          const hasPasswordProvider = signInMethods.includes('password');
          setHasPassword(hasPasswordProvider);
        } catch (error) {
          console.error('Error checking password status:', error);
        }

        const enrolledFactors = currentUser.multiFactor.enrolledFactors;
        setMfaEnabled(enrolledFactors.length > 0);
        setPhoneNumber(
          enrolledFactors.length > 0 ? enrolledFactors[0].phoneNumber : ''
        );
      }
    };
    fetchPasswordStatus();
  }, []);

  return (
    <div className="user-profile-container">
      <h2>User Profile</h2>
      {user ? (
        <>
          <section className="profile-section">
            <h3>Basic Information</h3>
            <p>
              <strong>Name:</strong> {user.displayName || 'Not Set'}
            </p>
            <p>
              <strong>Email:</strong> {user.email}
            </p>
          </section>

          {!hasPassword ? (
            <SetOrResetPassword
              isReset={false}
              setPasswordForUser={setPasswordForUser}
            />
          ) : (
            <SetOrResetPassword
              isReset={true}
              setPasswordForUser={setPasswordForUser}
            />
          )}

          <section className="profile-section">
            <div className="two-factor-header">
              <h3>Two-Factor Authentication (2MFA)</h3>
              {mfaEnabled && (
                <button
                  className="primary-button"
                  onClick={() =>
                    disable2FA(
                      setMfaEnabled,
                      setPhoneNumber,
                      setIsReauthenticating
                    )
                  }
                  disabled={isReauthenticating}
                >
                  {isReauthenticating ? 'Disabling...' : 'Disable 2FA'}
                </button>
              )}
            </div>
            <p>
              <strong>Status:</strong> {mfaEnabled ? 'Enabled' : 'Disabled'}
            </p>
            {phoneNumber && <MaskedPhoneNumber phoneNumber={phoneNumber} />}
            {!mfaEnabled ? (
              <Enable2FA
                setMfaEnabled={setMfaEnabled}
                setPhoneNumber={setPhoneNumber}
              />
            ) : (
              <EditPhoneNumber setPhoneNumber={setPhoneNumber} />
            )}
          </section>
        </>
      ) : (
        <p>Loading user data...</p>
      )}
      <style jsx>{`
        .user-profile-container {
          max-width: 600px;
          margin: 40px auto;
          padding: 20px;
          background: white;
          border-radius: 12px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        }

        h2 {
          ${fonts.bold};
          text-align: center;
          font-size: 24px;
          color: ${colors.dark};
          margin-bottom: 25px;
        }

        .two-factor-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .profile-section {
          background: #f9f9f9;
          padding: 20px;
          border-radius: 10px;
          margin-bottom: 20px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
          border-left: 4px solid ${colors.primary};
        }

        h3 {
          ${fonts.bold};
          font-size: 18px;
          color: ${colors.dark};
          margin-bottom: 10px;
        }

        p {
          ${fonts.reg};
          font-size: 14px;
          color: ${colors.dark};
          margin-bottom: 10px;
        }

        .input-group {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
        }

        .styled-input {
          flex: 1;
          padding: 10px;
          border-radius: 6px;
          border: 1px solid #ccc;
          background: #ffffff;
          font-size: 14px;
          transition: border 0.3s ease-in-out;
        }

        .styled-input:focus {
          border-color: ${colors.primary};
          outline: none;
        }

        .primary-button {
          background: ${colors.loBlue};
          color: white;
          padding: 10px 16px;
          font-size: 14px;
          border: none;
          border-radius: 6px;
          cursor: pointer;
          transition: background 0.3s ease-in-out;
        }

        .primary-button:hover {
          background: ${colors.darkBlue};
        }

        .error-text {
          color: ${colors.error};
          font-size: 13px;
          margin-top: 5px;
        }
      `}</style>
    </div>
  );
};

export default UserProfile;

import React from 'react';
import Menu from './Menu';
import Contact from './Contact';

class MainPanel extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <div className="MainPanel">
        <div className={this.props.sidebar ? 'sidebar active' : 'sidebar'}>
          <div className="close-button" onClick={this.props.onRouteChange}>
            <svg width="45" height="45" viewBox="0 0 45 45">
              <g>
                <path
                  d="M24.82,22.49l7-6.95a1.64,1.64,0,1,0-2.32-2.32l-6.95,7-6.95-7a1.64,1.64,0,1,0-2.32,2.32l7,7-6.95,7a1.64,1.64,0,0,0,2.32,2.31l7-7,7,7a1.64,1.64,0,0,0,2.32-2.31Z"
                  fillRule="evenodd"
                />
              </g>
            </svg>
          </div>
          <Menu onRouteChange={this.props.onRouteChange} />
          <Contact></Contact>
        </div>
        <div className="main">{this.props.children}</div>
        <style jsx="true">{`
          .MainPanel {
            display: flex;
            max-width: 1400px;
            padding: 40px;
            margin: auto;
            min-height: calc(100vh - 110px);
          }
          .sidebar {
            width: 220px;
            margin-right: 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .close-button {
            display: none;
          }

          @media screen and (max-width: 767px) {
            .MainPanel {
              display: block;
              padding: 10px;
              margin: 0px;
              overflow: hidden;
              width: 100vw;
            }
            .sidebar {
              position: fixed;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              background: #f5f5f5;
              z-index: 1000;
              width: 100%;
              padding: 10px 20px 10px 0px;
              transform: translateX(-100%);
              transition: all 0.3s;
              display: block;
            }
            .sidebar.active {
              transform: translateX(0%);
            }
            .close-button {
              display: block;
              text-align: right;
              margin-right: -10px;
              cursor: pointer;
            }
          }
          .main {
            flex: 1;
          }

          @media print {
            .sidebar {
              display: none;
            }
          }
        `}</style>
      </div>
    );
  }
}

MainPanel.propTypes = {};

export default MainPanel;

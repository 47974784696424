import React from 'react';
import { fonts } from '../../assets/css/theme';

class TableCell extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <div
        className={
          this.props.className
            ? `TableCell ${this.props.className}`
            : `TableCell`
        }
      >
        {this.props.children}

        <style jsx="true">{`
          .TableCell {
            ${fonts.bold};
            font-size: 14px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `}</style>
      </div>
    );
  }
}

TableCell.propTypes = {};

export default TableCell;

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { loginWithEmailPassword, setAuthFlow } from '../../actions/authAction';
import OTP from './OTP';
import PhoneRegistration from './PhoneRegistration';
import Logo from '../common/Logo';
import { colors, fonts } from '../../assets/css/theme';
import firebase from 'firebase/app';
import 'firebase/auth';

const PasswordSignIn = ({
  loginWithEmailPassword,
  onForgotPassword,
  setAuthFlow,
  authFlow,
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [mfaRequired, setMfaRequired] = useState(false);
  const [resolver, setResolver] = useState(null);
  const [needsPhoneRegistration, setNeedsPhoneRegistration] = useState(false);
  const [renderStep, setRenderStep] = useState(
    localStorage.getItem('renderStep') || 'login'
  );

  // Prevent state update on unmounted component
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleLogin = async () => {
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const result = await loginWithEmailPassword(email, password);
      //console.log('Login result:', result);
      const user = firebase.auth().currentUser;

      //console.log('User:', user);

      if (result.requiresMFA) {
        // console.log('MFA required, showing OTP screen');
        setMfaRequired(true);
        setResolver(result.resolver);
        setAuthFlow('otp');
        return;
      }

      if (!user) {
        // console.error('No user found after login!');
        setError('Login failed. Please try again.');
        return;
      }

      await user.reload();
      const multiFactorUser = user.multiFactor;
      //console.log('Checking MFA status...', multiFactorUser.enrolledFactors);

      if (
        !multiFactorUser.enrolledFactors ||
        multiFactorUser.enrolledFactors.length === 0
      ) {
        //console.log('No enrolled 2FA factors, enabling phone registration!');

        setAuthFlow('phone-registration');
        return;
      }
      setAuthFlow('dashboard');
      navigate('/');
    } catch (err) {
      setError(err.message || 'Failed to log in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const isLoginDisabled = !email || !password || loading;

  const handleLogout = async () => {
    try {
      //console.log('Logging out...');
      await firebase.auth().signOut();
      setAuthFlow('login');
      window.location.href = '/signin-password';
    } catch (error) {
      //console.error('Logout failed:', error);
      setError('Logout failed. Please try again.');
    }
  };

  if (authFlow === 'phone-registration') {
    // console.log('Rendering PhoneRegistration Component');
    return <PhoneRegistration logoutUser={handleLogout} />;
  }

  if (mfaRequired && resolver) {
    //console.log('Passing resolver to OTP page:', resolver);
    return <OTP resolver={resolver} />;
  }

  return (
    <div
      className="password-sign-in"
      style={{ backgroundColor: colors.loBlue }}
    >
      <Logo width={312} height={106} />
      <p className="title">Customer Order Portal</p>
      <div className="form">
        <input
          type="text"
          className="input-field"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          className="input-field"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          onClick={handleLogin}
          className="button"
          disabled={isLoginDisabled}
        >
          {loading ? 'Logging in...' : 'Log In'}
        </button>
      </div>

      <p className="bottom-links">
        <Link
          to="/signin-email"
          style={{ color: '#9fc9ff', textDecoration: 'underline' }}
        >
          Log in using One Time Email Link
        </Link>
      </p>
      <p className="bottom-links">
        <Link
          to="/forgot-password"
          style={{ color: '#9fc9ff', textDecoration: 'underline' }}
        >
          Forgot Password?
        </Link>
      </p>

      {error && <p className="error-message">{error}</p>}

      <style jsx="true">{`
        .password-sign-in {
          max-width: 650px;
          margin: 10px auto;
          text-align: center;
          padding: 40px;
          border-radius: 8px;
        }

        .title {
          ${fonts.reg}
          font-size: 35px;
          color: #fff;
          margin-bottom: 40px;
        }

        .form {
          display: flex;
          flex-direction: column;
          max-width: 360px;
          margin: auto;
        }

        .input-field {
          ${fonts.reg}
          height: 50px;
          padding: 0 14px;
          font-size: 14px;
          border: none;
          border-radius: 5px;
          margin-bottom: 20px;
          background-color: #fff;
          color: ${colors.dark};
        }

        .button {
          ${fonts.reg}
          background-color: ${colors.midBlue};
          color: #fff;
          font-size: 16px;
          padding: 10px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }

        .button:disabled {
          background-color: ${colors.mid};
          cursor: not-allowed;
        }

        .bottom-links {
          ${fonts.reg}
          font-size: 14px;
          margin-top: 15px;
          text-align: center;
        }

        .error-message {
          ${fonts.reg}
          color: #fff;
          font-weight: bold;
          margin-top: 15px;
        }
      `}</style>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authedUser: state.auth.authedUser,
  authFlow: state.auth.authFlow,
});

export default connect(mapStateToProps, {
  loginWithEmailPassword,
  setAuthFlow,
})(PasswordSignIn);

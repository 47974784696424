import { colors, fonts } from '../assets/css/theme';

const modalStyles = `
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    width: 100%;
    text-align: center;
  }
  .modal-title {
    ${fonts.reg};
    font-size: 18px;
    color: ${colors.dark};
    font-weight: bold;
    margin-bottom: 12px;
  }
  .modal-input {
    ${fonts.reg};
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background: #ffffff;
    font-size: 14px;
    transition: border 0.3s ease-in-out;
    text-align: center;
  }
  .modal-input:focus {
    border-color: ${colors.primary};
    outline: none;
  }
  .modal-actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .primary-button {
    ${fonts.reg};
    background: ${colors.loBlue};
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  .primary-button:hover {
    background: ${colors.darkBlue};
  }
  .secondary-button {
    ${fonts.reg};
    background: #ccc;
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  .secondary-button:hover {
    background: #bbb;
  }
`;

export default modalStyles;

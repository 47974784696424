import React from 'react';
import { colors } from '../../assets/css/theme';
import eyeSrc from './../../assets/img/eye.svg';
class ViewButton extends React.Component {
  constructor(props) {
    super(props);
    this.size = 28;
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <div className="Button" onClick={this.props.onClick}>
        <img className="Button-img" src={eyeSrc} alt="" />
        <style global="false" jsx="true">{`
          .Button {
            background: ${colors.midBlue};
            color: #fff;
            width: ${this.size}px;
            height: ${this.size}px;
            border: none;
            border-radius: 5px;
            display: inline-block;
            transition: all 0.3s;
            cursor: pointer;
          }
          .Button-img {
            width: ${this.size}px;
            height: ${this.size}px;
          }
          .Button:hover {
            opacity: 0.9;
          }
          .Button:disabled {
            background: ${colors.mid};
          }
        `}</style>
      </div>
    );
  }
}

ViewButton.propTypes = {};

export default ViewButton;

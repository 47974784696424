import React from 'react';
import { colors, fonts } from '../../assets/css/theme';

class TableHeader extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <div className={'TableHeader ' + this.props.className}>
        {this.props.children}
        <style jsx="true">{`
                    .TableHeader {
                        ${fonts.bold}
                        font-weight: 700!important;
                        font-size:14px;
                        color:${colors.midBlue};
                        text-align: center;
                `}</style>
      </div>
    );
  }
}

TableHeader.propTypes = {};

export default TableHeader;

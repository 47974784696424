import firebase from 'firebase/app';
import 'firebase/auth';
import setPasswordForUser from './setPasswordForUser';
import modalStyles from './modalStyles';

const reauthenticateUser = async (
  setIsReauthenticated,
  setIsReauthenticating = () => {},
  actionType = ''
) => {
  console.log('Entering reauthenticateUser function');
  console.log('Action Type:', actionType);

  const user = firebase.auth().currentUser;
  if (!user) {
    console.error('No authenticated user found.');
    return;
  }

  const providerData = user.providerData.find(
    (provider) => provider.providerId === 'password'
  );

  if (!providerData) {
    return new Promise((resolve, reject) => {
      const modal = createPasswordModal(resolve, reject);
      document.body.appendChild(modal);
    });
  }

  setIsReauthenticating(true);

  try {
    await showReauthModal(user, setIsReauthenticated);
    return true;
  } catch (error) {
    console.log('Password reauth failed. Checking for MFA requirement...');

    if (error.code === 'auth/multi-factor-auth-required') {
      return handleMfaReauthentication(error, setIsReauthenticated);
    } else {
      console.error('Reauthentication failed:', error);
      throw error;
    }
  } finally {
    setIsReauthenticating(false);
  }
};

// Function to show reauthentication modal for password users
const showReauthModal = (user, setIsReauthenticated) => {
  return new Promise((resolve, reject) => {
    const modal = document.createElement('div');
    modal.innerHTML = `
      <div class="modal-overlay">
        <div class="modal-content">
          <h3 class="modal-title">Enter your current password</h3>
          <input id="password-input" type="password" class="modal-input" placeholder="Enter password" />
          <div class="modal-actions">
            <button id="cancel-btn" class="secondary-button">Cancel</button>
            <button id="confirm-btn" class="primary-button">Confirm</button>
          </div>
        </div>
      </div>
      <style>${modalStyles}</style>
    `;

    document.body.appendChild(modal);

    modal.querySelector('#confirm-btn').addEventListener('click', async () => {
      const password = modal.querySelector('#password-input').value;
      modal.remove();
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          password
        );
        await user.reauthenticateWithCredential(credential);
        console.log('Password-based reauthentication successful.');
        setIsReauthenticated(true);
        resolve();
      } catch (err) {
        console.error('Password reauthentication failed:', err);
        reject(err);
      }
    });

    modal.querySelector('#cancel-btn').addEventListener('click', () => {
      modal.remove();
      reject('User cancelled reauthentication.');
    });
  });
};

// Function to create a modal for setting a password
const createPasswordModal = (resolve, reject) => {
  const modal = document.createElement('div');
  modal.innerHTML = `
    <div class="modal-overlay">
      <div class="modal-content">
        <h3 class="modal-title">Set a Password</h3>
        <input id="set-password-input" type="password" class="modal-input" placeholder="Enter new password" />
        <div class="modal-actions">
          <button id="cancel-btn" class="secondary-button">Cancel</button>
          <button id="set-password-btn" class="primary-button">Set Password</button>
        </div>
      </div>
    </div>
    <style>${modalStyles}</style>
  `;

  modal
    .querySelector('#set-password-btn')
    .addEventListener('click', async () => {
      const newPassword = modal.querySelector('#set-password-input').value;
      modal.remove();
      if (!newPassword) {
        reject('Password cannot be empty.');
        return;
      }

      const success = await setPasswordForUser(newPassword);
      success ? resolve() : reject('Failed to set password. Try again.');
    });

  modal.querySelector('#cancel-btn').addEventListener('click', () => {
    modal.remove();
    reject('User cancelled setting password.');
  });

  return modal;
};

// Function to handle MFA-based reauthentication
const handleMfaReauthentication = async (error, setIsReauthenticated) => {
  console.log('MFA required! Requesting OTP verification...');

  return new Promise(async (resolve, reject) => {
    try {
      const resolver = error.resolver;
      const phoneFactor = resolver.hints[0];

      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          { size: 'invisible' }
        );
      }

      const session = await resolver.session;
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        { session, multiFactorHint: phoneFactor },
        window.recaptchaVerifier
      );

      const modal = createOtpModal(
        verificationId,
        resolver,
        setIsReauthenticated,
        resolve,
        reject
      );
      document.body.appendChild(modal);
    } catch (err) {
      console.error('Error during MFA reauthentication:', err);
      reject(err);
    }
  });
};

// Function to create OTP modal for MFA
const createOtpModal = (
  verificationId,
  resolver,
  setIsReauthenticated,
  resolve,
  reject
) => {
  const modal = document.createElement('div');
  modal.innerHTML = `
    <div class="modal-overlay">
      <div class="modal-content">
        <h3 class="modal-title">Enter OTP</h3>
        <input id="otp-input" type="text" class="modal-input" placeholder="Enter OTP Code" />
        <div class="modal-actions">
          <button id="cancel-btn" class="secondary-button">Cancel</button>
          <button id="verify-btn" class="primary-button">Verify</button>
        </div>
      </div>
    </div>
    <style>${modalStyles}</style>
  `;

  modal.querySelector('#verify-btn').addEventListener('click', async () => {
    const otpCode = modal.querySelector('#otp-input').value;
    modal.remove();
    if (!otpCode) {
      reject('OTP cannot be empty.');
      return;
    }

    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        otpCode
      );
      const assertion =
        firebase.auth.PhoneMultiFactorGenerator.assertion(credential);
      await resolver.resolveSignIn(assertion);
      console.log('MFA OTP reauthentication successful.');
      setIsReauthenticated(true);
      resolve();
    } catch (err) {
      reject('MFA OTP verification failed: ' + err.message);
    }
  });

  modal.querySelector('#cancel-btn').addEventListener('click', () => {
    modal.remove();
    reject('User cancelled OTP verification.');
  });

  return modal;
};

export default reauthenticateUser;

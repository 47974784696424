import React from 'react';

const MaskedPhoneNumber = ({ phoneNumber }) => {
  if (!phoneNumber) return <p>No phone number set.</p>;

  let normalizedPhone = phoneNumber.replace(/^\+440/, '+44');
  const numericPhone = normalizedPhone.replace(/[^\d+]/g, '');
  const testNumbers = ['+16505553434'];

  if (testNumbers.includes(numericPhone)) {
    return (
      <p>
        <strong>Phone Number:</strong> +1 ***-***-**34
      </p>
    );
  }

  const masked = normalizedPhone.replace(
    /(\+\d{2})(\d{2})(\d{3})(\d{3})(\d{2})$/,
    '$1** *** ***$5'
  );

  return (
    <p>
      <strong>Phone Number:</strong> {masked}
    </p>
  );
};

export default MaskedPhoneNumber;

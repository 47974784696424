import React from 'react';
import { connect } from 'react-redux';
import { effects, colors, fonts } from '../../assets/css/theme';
import SearchBox from '../common/SearchBox';
import Fuse from 'fuse.js';
import TableRow from '../common/TableRow';
import TableCell from './../common/TableCell';
import moment from 'moment';
import ViewButton from '../common/ViewButton';
import Order from '../common/Order';
import Popover from '../common/Popover';
import Status from '../common/Status';
import _ from 'lodash';
import OrdersList from '../common/OrdersList';
import BackordersList from '../common/BackordersList';
import withRouter from '../../util/withRouter';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilter = this.updateFilter.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.toggleOnlyOpen = this.toggleOnlyOpen.bind(this);
    this.searchFields = [
      'CustomerRef',
      'Total',
      'OrderPlacedByName',
      'SearchString',
    ];
    this.state = {
      filterText: '',
      paginationCount: 15,
      currentPage: 0,
      totalPages: 1,
      records: [],
      paginationPages: [],
      selectedRecord: null,
      onlyOpen: false,
      backOrders: false,
    };
  }

  updateFilter(filterVal) {
    this.setState(
      {
        filterText: filterVal,
        currentPage: 0,
      },
      () => {
        setTimeout(() => {
          this.setInternals(this.props);
        });
      }
    );
  }

  componentDidMount() {
    this.setInternals(this.props);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setInternals(newProps);
  }

  setInternals(props) {
    let records = props.records.slice();

    records = records.map((r) => {
      r.SearchString = '';
      r.Lines = r.Lines.map((l) => {
        if (l != undefined && l.ItemCode.ItemName != undefined) {
          r.SearchString +=
            this.props.itemsByKey[l.ItemCode]?.ItemName || '' + ' ';
        }
        return l;
      });
      return r;
    });

    if (this.state.onlyOpen) {
      records = records.filter((r) => {
        let allowedStatus = ['New', 'Open'];
        return allowedStatus.indexOf(r.Status) > -1;
      });
    }

    if (this.state.filterText) {
      const fuse = new Fuse(records, {
        shouldSort: true,
        tokenize: false,
        threshold: 0.2,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: this.searchFields,
      });
      records = fuse.search(this.state.filterText);
    }

    records = _.sortBy(records, 'DocDate').reverse();

    this.setState({
      records: records,
      totalPages: Math.ceil(records.length / this.state.paginationCount),
    });
  }
  toggleOnlyOpen() {
    this.setState(
      {
        onlyOpen: !this.state.onlyOpen,
        currentPage: 0,
      },
      () => {
        setTimeout(() => {
          this.setInternals(this.props);
        });
      }
    );
  }
  renderRows() {
    const formatDate = (str) => {
      if (!str) return '';
      try {
        return moment(str).format('DD-MM-YYYY');
      } catch (e) {
        return '';
      }
    };

    return this.state.records
      .filter((i, idx) => {
        //pagination filter
        if (
          idx >
          this.state.currentPage * this.state.paginationCount +
            (this.state.paginationCount - 1)
        )
          return false;
        if (idx < this.state.currentPage * this.state.paginationCount)
          return false;
        return true;
      })
      .map((r, idx) => {
        return (
          <TableRow key={idx}>
            <TableCell className="date">{formatDate(r.DocDate)}</TableCell>
            <TableCell className="po">{r.CustomerRef}</TableCell>
            <TableCell className="name">{r.OrderPlacedByName}</TableCell>
            <TableCell className="status">
              {<Status mode="orders">{r.Status}</Status>}
            </TableCell>
            <TableCell className="total">£{r.Total.toFixed(2)}</TableCell>
            <TableCell className="control">
              <ViewButton
                onClick={() => {
                  this.setState({ selectedRecord: r });
                }}
              />
            </TableCell>
          </TableRow>
        );
      });
  }

  render() {
    const renderCurrentOrderTab = () => {
      if (!this.props.cart || !this.props.cart.Total) return '';

      return (
        <div
          className="currentOrder"
          onClick={() => {
            this.props.router.navigate('/catalogue?cart');
          }}
        >
          Current Order: £{this.props.cart.Total.toFixed(2)}
          <img
            className="currentOrder-img"
            src={require('./../../assets/img/follow.svg').default}
            alt=""
          />
          <style jsx="true">{`
            .currentOrder {
              display: flex;
              ${fonts.bold}
              color:${colors.midBlue};
              align-items: center;
              padding-left: 10px;
              font-size: 12px;
              padding-right: 10px;
              cursor: pointer;
              text-transform: uppercase;
            }
            .currentOrder:hover .currentOrder-img {
              transform: scale(1.2);
            }
            .currentOrder-img {
              width: 20px;
              height: 20px;
              margin-left: 5px;
              transition: all 0.3s;
            }
          `}</style>
        </div>
      );
    };

    //rend orders mode

    const renderLists = () => {
      if (this.state.backOrders) {
        return (
          <BackordersList
            records={this.props.backOrders}
            onOrderSelect={(orderId) => {
              this.setState({
                selectedRecord: this.props.ordersByKey[orderId],
              });
            }}
            itemsByKey={this.props.itemsByKey}
          />
        );
      }
      return (
        <OrdersList
          onlyOpenValue={this.state.onlyOpen}
          onlyOpenAction={this.toggleOnlyOpen}
          currentPage={this.state.currentPage}
          paginationCount={this.state.paginationCount}
          records={this.state.records}
          totalPages={this.state.totalPages}
          onPaginationChange={(page) => {
            this.setState({ currentPage: page });
          }}
          onOrderSelect={(order) => {
            this.setState({ selectedRecord: order });
          }}
        />
      );
    };
    const renderSearch = () => {
      return (
        <div
          className="search-area"
          style={{ opacity: this.state.backOrders ? 0 : 1 }}
        >
          <SearchBox
            placeholder="Search by PO, invoice amount or product"
            value={this.state.filterText}
            onChange={(val) => {
              this.updateFilter(val);
            }}
          />
          <style jsx="true">{`
            .search-area {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }
            @media screen and (max-width: 767px) {
              .search-area {
                display: none;
              }
            }
          `}</style>
        </div>
      );
    };
    return (
      <div className="Orders">
        <div className="tabs">
          <div
            className={this.state.backOrders ? 'tab' : 'tab active'}
            onClick={() => this.setState({ backOrders: false })}
          >
            Orders
          </div>
          <div
            className={this.state.backOrders ? 'tab active' : 'tab'}
            onClick={() => this.setState({ backOrders: true })}
          >
            Back Orders
          </div>
          {renderCurrentOrderTab()}
          {renderSearch()}
        </div>

        {renderLists()}

        <Popover
          isOpen={this.state.selectedRecord}
          onClose={() => {
            this.setState({ selectedRecord: null });
          }}
        >
          <Order order={this.state.selectedRecord} title="Order" />
        </Popover>
        <style jsx>{`
          .Orders-wrap {
            padding: 20px;
            background: #fff;
            ${effects.shadow1}
            margin-top:0px;
            min-height: 70vh;
          }
          .toggleWrap {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
          }

          .tabs {
            display: flex;
            position: relative;
          }

          .tab {
            background: ${colors.loBlue};
            width: 220px;
            height: 45px;
            padding: 0px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-right: 5px;
            margin-bottom: 0px;
            border-radius: 2px 30px 0px 0px;
            ${effects.tabShadow}
            ${fonts.bold}
                        font-size:16px;
            color: #fff;
            justify-content: space-between;
            cursor: pointer;
            z-index: 0;
          }

          .tab:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0px;
            right: 0px;
            display: block;
            height: 10px;
            background: #fff;
            z-index: 1;
          }
          .tab.active {
            background: #fff;
            color: ${colors.midBlue};
            cursor: default;
            position: relative;
            ${effects.shadow1}
            z-index: 1;
          }
        `}</style>

        <style global="true" jsx>{`
          .date {
            text-align: left;
            width: 150px;
          }
          .po {
            flex: 1;
            text-align: left;
          }

          .name {
            width: 100px;
          }

          .price {
            width: 100px;
          }

          .status {
            width: 100px;
            white-space: normal;
            font-size: 0.9em;
          }
          .total {
            width: 100px;
            text-align: right;
          }

          .control {
            width: 50px;
            text-align: right;
          }
          @media screen and (max-width: 767px) {
            .date {
              text-align: left;
              width: 100%;
            }
            .po {
              flex: 1;
              text-align: left;
            }

            .name {
              width: 100%;
              text-align: left;
              margin-bottom: 10px;
            }

            .price {
              width: 100%;
              text-align: left;
            }

            .status {
              text-align: left;
              font-size: 0.9em;
            }
            .total {
              width: auto;
              text-align: right;
              font-size: 18px;
              flex: 1;
            }

            .control {
              width: 50px;
              text-align: right;
            }
          }
        `}</style>
      </div>
    );
  }
}

Orders.propTypes = {};

const mapStateProps = (state) => {
  return {
    records: state.customer.orders,
    itemsByKey: state.customer.itemsByKey,
    backOrders: state.customer.backOrders,
    ordersByKey: state.customer.ordersByKey,
    cart: state.cart.cart,
  };
};

export default withRouter(connect(mapStateProps, {})(Orders));

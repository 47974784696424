import React from 'react';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import './../assets/css/styles.scss';

import { loadFirebase, sidebarActive } from '../actions/appAction';
import { initProfile } from '../actions/profileAction';
import { fonts, colors } from '../assets/css/theme';
import CentralFrame from './common/CentralFrame';
import EmailSignIn from './auth/EmailSignIn';
import OTP from './auth/OTP';
import CreateUserProfile from './screens/CreateUserProfile';
import AwaitingActivation from './common/AwaitingActivation';
import HeaderBar from './common/HeaderBar';
import Dashboard from './screens/Dashboard';
import Catalogue from './screens/Catalogue';
import Deliveries from './screens/Deliveries';
import Orders from './screens/Orders';
import Invoices from './screens/Invoices';
import MainPanel from './common/MainPanel';
import Invoice from './screens/Invoice';
import Credits from './screens/Credits';
import PasswordSignIn from './auth/PasswordSignIn';
import PhoneRegistration from './auth/PhoneRegistration';
import ForgotPassword from './auth/ForgotPassword';
import UserProfile from './screens/UserProfile';

class ArcadiaPortal extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {
    this.props.loadFirebase();
  }

  render() {
    if (!this.props.connected || !this.props.authChecked) {
      return (
        <CentralFrame style={{ background: colors.loBlue }}>
          <p className="message">Loading</p>
          <style jsx="true">{`
            .message {
              ${fonts.reg}
              text-align: center;
              color: #fff;
              max-width: 450px;
              margin: auto;
            }
          `}</style>
        </CentralFrame>
      );
    }

    if (
      !this.props.authedUser ||
      !this.props.authFlow ||
      ['phone-registration', 'otp', 'login'].includes(this.props.authFlow)
    ) {
      return (
        <CentralFrame style={{ background: colors.loBlue }}>
          <Router>
            <Routes>
              <Route path="/signin-email" element={<EmailSignIn />} />
              <Route path="/signin-password" element={<PasswordSignIn />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/otp" element={<OTP />} />
              <Route path="/2fa-registration" element={<PhoneRegistration />} />
              <Route path="*" element={<EmailSignIn />} />
            </Routes>
          </Router>
        </CentralFrame>
      );
    }

    if (!this.props.profileChecked) {
      return (
        <CentralFrame>
          <p className="message">Loading</p>
          <style jsx="true">{`
            .message {
              ${fonts.reg}
              text-align: center;
              color: #fff;
              max-width: 450px;
              margin: auto;
            }
          `}</style>
        </CentralFrame>
      );
    }

    if (!this.props.profile) {
      return (
        <CentralFrame style={{ background: colors.midHi }}>
          <CreateUserProfile />
        </CentralFrame>
      );
    }

    if (!this.props.enabledForCustomerChecked) {
      return (
        <CentralFrame>
          <p className="message">Loading</p>
          <style jsx="true">{`
            .message {
              ${fonts.reg}
              text-align: center;
              color: #fff;
              max-width: 450px;
              margin: auto;
            }
          `}</style>
        </CentralFrame>
      );
    }

    // TODO Check if Staging environment url to avoid displaying Awaiting Activation
    if (!this.props.enabledForCustomer) {
      return (
        <CentralFrame style={{ background: colors.midHi }}>
          <AwaitingActivation />
        </CentralFrame>
      );
    }

    // users permissions haven't been loaded yet
    // if(!this.props.permissionsLoaded) {
    //     return (
    //         <CentralFramer>
    //              <Loading>Loading your account</Loading>
    //         </CentralFramer>
    //     )
    // }

    // //users profile hasn't loaded yet
    // if(!this.props.profileLoaded){
    //     return (
    //         <CentralFramer>
    //             <Loading>Loading your profile</Loading>
    //         </CentralFramer>
    //     )
    // }

    return (
      <div className="ArcadiaPortal">
        <Router>
          <div>
            <HeaderBar />
            <MainPanel
              sidebar={this.props.sidebar}
              onRouteChange={() => {
                this.props.sidebarActive(false);
              }}
            >
              <Routes>
                <Route path="/" exact="true" element={<Dashboard />} />
                <Route path="/catalogue" exact="true" element={<Catalogue />} />
                <Route path="/deliveries" element={<Deliveries />} />
                <Route path="/deliveries/:id" element={<Deliveries />} />
                <Route path="/credits" element={<Credits />} />
                <Route path="/credits/:id" element={<Credits />} />
                <Route path="/orders" exact="true" element={<Orders />} />
                <Route path="/invoices" exact="true" element={<Invoices />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route
                  path="/invoices/:id"
                  exact="true"
                  element={<Invoice />}
                />
              </Routes>
            </MainPanel>
          </div>
        </Router>

        <style jsx="true">{`
          .ArcadiaPortal {
            ${fonts.reg}
            color : ${colors.dark};
          }
          @media print {
            .ArcadiaPortal {
            }
          }
        `}</style>
        <style global="true" jsx="true">{`
          @media print {
            .HeaderBar {
              display: none;
            }
            .tabs,
            .Deliveries-records,
            .Orders-records,
            .toggleWrap,
            .PaginationControls,
            .Invoices-records,
            .Credits-records {
              opacity: 0 !important;
            }
          }
        `}</style>
      </div>
    );
  }
}

ArcadiaPortal.propTypes = {};

const mapStateProps = (state) => {
  return {
    connected: state.app.connected,
    authChecked: state.auth.authChecked,
    authedUser: state.auth.authedUser,
    profile: state.profile.profile,
    profileChecked: state.profile.profileChecked,
    enabledForCustomer: state.auth.enabledForCustomer,
    enabledForCustomerChecked: state.auth.enabledForCustomerChecked,
    sidebar: state.app.sidebar,
    authFlow: state.auth.authFlow,
  };
};

export default connect(mapStateProps, {
  loadFirebase,
  initProfile,
  sidebarActive,
})(ArcadiaPortal);

import React from 'react';
import { connect } from 'react-redux';
import { effects, colors, fonts } from '../../assets/css/theme';
import SearchBox from '../common/SearchBox';
import Fuse from 'fuse.js';
import PaginationControls from '../common/PaginationControls';
import TableRow from '../common/TableRow';
import TableCell from './../common/TableCell';
import TableHeader from '../common/TableHeader';
import moment from 'moment';
import ViewButton from '../common/ViewButton';
import PrintButton from '../common/PrintButton';
import Order from '../common/Order';
import Popover from '../common/Popover';
import Status from '../common/Status';
import _ from 'lodash';
import withRouter from '../../util/withRouter';

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilter = this.updateFilter.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.searchFields = [
      'CustomerRef',
      'Total',
      'OrderPlacedByName',
      'SearchString',
    ];
    this.state = {
      filterText: '',
      paginationCount: 15,
      currentPage: 0,
      totalPages: 1,
      records: [],

      paginationPages: [],
      selectedRecord: null,
    };
  }

  updateFilter(filterVal) {
    let attrs = {
      filterText: filterVal,
    };

    this.setState(attrs, () => {
      this.setInternals(this.props);
    });
  }

  componentDidMount() {
    this.setInternals(this.props);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.setInternals(newProps);
  }

  setInternals(props) {
    let records = props.records.slice();

    records = records.map((r) => {
      r.SearchString = '';
      if (r.Lines) {
        r.Lines = r.Lines.map((l) => {
          if (props.itemsByKey[l.ItemCode]) {
            r.SearchString += props.itemsByKey[l.ItemCode]?.ItemName + ' ';
          }
          return l;
        });
      }

      return r;
    });

    if (this.state.filterText) {
      const fuse = new Fuse(records, {
        shouldSort: true,
        tokenize: false,
        threshold: 0.2,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: this.searchFields,
      });
      records = fuse.search(this.state.filterText);
    }

    records = _.sortBy(records, 'DocDate').reverse();

    this.setState({
      records: records,
      totalPages: Math.ceil(records.length / this.state.paginationCount),
    });
  }

  renderRows() {
    const formatDate = (str) => {
      if (!str) return '';
      try {
        return moment(str).format('DD-MM-YYYY');
      } catch (e) {
        return '';
      }
    };

    return this.state.records
      .filter((i, idx) => {
        //pagination filter
        if (
          idx >
          this.state.currentPage * this.state.paginationCount +
            (this.state.paginationCount - 1)
        )
          return false;
        if (idx < this.state.currentPage * this.state.paginationCount)
          return false;
        return true;
      })
      .map((r, idx) => {
        // When filtering the orders by searching the items are
        // returned with a wrapping object and the actual data is
        // inside the "item" key. This is a quick fix and should
        // probably be fixed at the root but that may break other
        // parts of the site.
        r = typeof r.CustomerRef === 'undefined' ? r.item : r;

        return (
          <TableRow key={idx}>
            <TableCell className="date">{formatDate(r.DocDate)}</TableCell>
            <TableCell className="inv">{r.id}</TableCell>
            <TableCell className="po">{r.CustomerRef}</TableCell>
            <TableCell className="status">
              {<Status>{r.Status}</Status>}
            </TableCell>
            <TableCell className="total">
              £{(r.TaxTotal + r.Total)?.toFixed(2)}
            </TableCell>
            <TableCell className="control">
              <ViewButton
                onClick={() => {
                  this.setState({ selectedRecord: r });
                }}
              />
            </TableCell>
            <TableCell className="control">
              <PrintButton
                onClick={() => {
                  this.props.router.navigate(`/invoices/${r.id}`);
                }}
              />
            </TableCell>
          </TableRow>
        );
      });
  }

  render() {
    const renderCurrentOrderTab = () => {
      return '';
    };

    return (
      <div className="Invoices">
        <div className="tabs">
          <div className="tab">Invoices</div>
          {renderCurrentOrderTab()}
          <div className="search-area">
            <SearchBox
              placeholder="Search by PO, invoice amount or product"
              value={this.state.filterText}
              onChange={(val) => {
                this.updateFilter(val);
              }}
            />
          </div>
        </div>

        <div className="Invoices-wrap">
          <div className="Invoices-records">
            <TableRow>
              <TableHeader className="date">Date</TableHeader>
              <TableHeader className="inv">Invoice Number</TableHeader>
              <TableHeader className="po">PO Number</TableHeader>
              <TableHeader className="status">Status</TableHeader>
              <TableHeader className="total">Total</TableHeader>
              <TableHeader className="control"> </TableHeader>
              <TableHeader className="control"> </TableHeader>
            </TableRow>
            {this.renderRows()}
          </div>

          <PaginationControls
            style={{ marginTop: 20 }}
            totalPages={this.state.totalPages}
            currentPage={this.state.currentPage}
            onChange={(page) => {
              this.setState({ currentPage: page });
            }}
          />

          <Popover
            isOpen={this.state.selectedRecord}
            onClose={() => {
              this.setState({ selectedRecord: null });
            }}
          >
            <Order order={this.state.selectedRecord} title="Invoice" />
          </Popover>
        </div>
        <style jsx>{`
          .Invoices-wrap {
            padding: 20px;
            background: #fff;
            ${effects.shadow1}
            margin-top:0px;
            min-height: 70vh;
          }
          .tabs {
            display: flex;
            position: relative;
          }
          .search-area {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .tab {
            background: #fff;
            color: ${colors.midBlue};
            width: 220px;
            height: 45px;
            padding: 0px 10px 0px 20px;
            display: flex;
            align-items: center;
            margin-right: 5px;
            margin-bottom: 0px;
            border-radius: 2px 30px 0px 0px;
            ${effects.shadow1}
            ${fonts.bold}
                        font-size:16px;
            cursor: default;
            z-index: 1;
          }

          .tab:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0px;
            right: 0px;
            display: block;
            height: 10px;
            background: #fff;
            z-index: 1;
          }
          :global(.date) {
            text-align: left;
            width: 100px;
          }
          :global(.po) {
            flex: 1;
            text-align: left;
          }
          :global(.inv) {
            width: 200px;
            text-align: left;
          }

          :global(.name) {
            width: 180px;
          }

          :global(.status) {
            width: 100px;
            white-space: normal;
            font-size: 0.9em;
          }
          :global(.total) {
            width: 100px;
            text-align: right;
          }
          :global(.control) {
            width: 50px;
            text-align: right;
          }

          @media screen and (max-width: 767px) {
            .search-area {
              display: none;
            }
            :global(.inv) {
              text-align: left;
              width: 100%;
            }
            :global(.date) {
              text-align: left;
              width: 100%;
            }
            :global(.track) {
              display: none;
            }
            :global(.po) {
              flex: 1;
              text-align: left;
              width: 100%;
            }
            :global(.name) {
              width: 100%;
              text-align: left;
              margin-bottom: 10px;
            }
            :global(.price) {
              width: 100%;
              text-align: left;
            }

            :global(.status) {
              text-align: left;
              font-size: 0.9em;
            }
            :global(.total) {
              width: auto;
              text-align: right;
              font-size: 18px;
              flex: 1;
            }
            :global(.control) {
              width: 50px;
              text-align: right;
            }
            :global(.TableHeader) {
              display: none;
            }
          }
        `}</style>
      </div>
    );
  }
}

Invoices.propTypes = {};

const mapStateProps = (state) => {
  return {
    records: state.customer.invoices,
    itemsByKey: state.customer.itemsByKey,
  };
};

export default withRouter(connect(mapStateProps, {})(Invoices));

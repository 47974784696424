import React from 'react';
import { colors, fonts } from '../../assets/css/theme';
import { ScaleLoader } from 'react-spinners';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
    };
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <button
        className="Button"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        style={{ ...this.props.style }}
      >
        {this.props.working ? '' : this.props.children}
        <ScaleLoader
          css={{ position: 'relatvie', top: 5 }}
          sizeUnit={'px'}
          size={10}
          height={14}
          color={'#fff'}
          loading={this.props.working}
        />

        <style global="false" jsx="true">{`
          .Button {
            background: ${colors.midBlue};
            color: #fff;
            ${fonts.bold}
            font-size:14px;
            border: none;
            border-radius: 5px;
            display: inline-block;
            transition: all 0.3s;
            cursor: pointer;
          }
          .Button:hover {
            opacity: 0.9;
          }
          .Button:disabled {
            background: ${colors.mid};
          }
        `}</style>
      </button>
    );
  }
}

Button.propTypes = {};

export default Button;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import Logo from '../common/Logo';
import { colors, fonts } from '../../assets/css/theme';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setMessage(null);
    setError(null);

    if (!email) {
      setError('Please enter your email.');
      return;
    }

    setLoading(true);

    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setMessage(
        'A password reset email has been sent. Check your inbox and follow the instructions.'
      );
    } catch (err) {
      setError(err.message || 'Failed to send password reset email.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password" style={{ backgroundColor: colors.loBlue }}>
      <Logo width={312} height={106} />
      <p className="title">Reset Your Password</p>

      {message ? (
        <p className="confirmation">{message}</p>
      ) : (
        <>
          <div className="form">
            <input
              type="email"
              className="input-field"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handlePasswordReset}
              className="button"
              disabled={loading || !email}
            >
              {loading ? 'Sending...' : 'Send Reset Link'}
            </button>
          </div>

          {error && <p className="error-message">{error}</p>}
        </>
      )}

      <p className="bottom-links">
        <Link
          to="/signin-password"
          style={{ color: '#9fc9ff', textDecoration: 'underline' }}
        >
          Back to Login
        </Link>
      </p>

      <style jsx="true">{`
        .forgot-password {
          max-width: 650px;
          margin: 10px auto;
          text-align: center;
          padding: 40px;
          border-radius: 8px;
        }

        .title {
          ${fonts.reg}
          font-size: 35px;
          color: #fff;
          margin-bottom: 20px;
        }

        .form {
          display: flex;
          flex-direction: column;
          max-width: 360px;
          margin: auto;
        }

        .input-field {
          ${fonts.reg}
          height: 50px;
          padding: 0 14px;
          font-size: 14px;
          border: none;
          border-radius: 5px;
          margin-bottom: 20px;
          background-color: #fff;
          color: ${colors.dark};
        }

        .button {
          ${fonts.reg}
          background-color: ${colors.midBlue};
          color: #fff;
          font-size: 16px;
          padding: 10px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }

        .button:disabled {
          background-color: ${colors.mid};
          cursor: not-allowed;
        }

        .error-message {
          ${fonts.reg}
          color: #ff7777;
          font-weight: bold;
          margin-top: 15px;
        }

        .confirmation {
          ${fonts.reg}
          color: #9fc9ff;
          font-size: 14px;
          margin-top: 10px;
        }

        .bottom-links {
          ${fonts.reg}
          font-size: 14px;
          margin-top: 15px;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default ForgotPassword;

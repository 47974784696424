import React from 'react';
import { connect } from 'react-redux';
import { updateLineItems } from '../../actions/cartAction';
import incrSrc from './../../assets/img/plus.svg';
import decrSrc from './../../assets/img/minus.svg';
import { fonts, colors } from '../../assets/css/theme';

class OrderStepper extends React.Component {
  constructor(props) {
    super(props);
    this.getQty = this.getQty.bind(this);
    this.incr = this.incr.bind(this);
    this.decr = this.decr.bind(this);
    this.update = this.update.bind(this);
  }
  UNSAFE_componentWillMount() {}

  componentDidMount() {}

  getQty() {
    if (!this.props.lineItem) return 0;
    return this.props.lineItem.Quantity;
  }
  incr() {
    let qty = this.getQty() || 0;
    this.props.updateLineItems(this.props.itemCode, qty + 1);
  }

  update(e) {
    if (e.target.value === '') {
      this.props.updateLineItems(this.props.itemCode, 0);
    } else {
      let value = parseInt(e.target.value);
      if (!isNaN(value)) {
        this.props.updateLineItems(this.props.itemCode, value);
      }
    }
  }
  decr() {
    let qty = this.getQty() || 0;
    qty = qty - 1;
    if (qty < 1) {
      qty = 0;
    }
    this.props.updateLineItems(this.props.itemCode, qty);
  }

  render() {
    return (
      <div className="OrderStepper">
        <div onClick={this.decr}>
          <img src={decrSrc} alt="" className="control" />
        </div>
        <input
          className="currentVal"
          value={this.getQty()}
          type="text"
          onChange={this.update}
        />
        <div onClick={this.incr}>
          <img src={incrSrc} alt="" className="control" />
        </div>

        <style jsx="true">{`
          .OrderStepper {
            margin: 0 auto;
            width: 120px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .control {
            width: 32px;
            height: 32px;
            cursor: pointer;
          }
          .currentVal {
            ${fonts.bold}
            font-size:16px;
            color: ${colors.dark};
            margin: 0px;
            width: 56px;
            text-align: center;
            height: 32px;
            border: 1px solid #cecece;
            border-radius: 16px;
            display: block;
            margin: 0px 2px 0px 2px;
            transform: translateY(-2px);
          }
        `}</style>
      </div>
    );
  }
}

OrderStepper.propTypes = {};

const mapStateProps = (state, props) => {
  return {
    lineItem: state.cart.itemsByKey[props.itemCode] || null,
  };
};

export default connect(mapStateProps, { updateLineItems })(OrderStepper);
